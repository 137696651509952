import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getSuppliersListSuccess,
    getSuppliersListFailure,

    getTopSupplierCategoriesSuccess,
    getTopSupplierCategoriesFailure,

    getSubSupplierCategoriesSuccess,
    getSubSupplierCategoriesFailure,

    sendUseSupplierFormSuccess,
    sendUseSupplierFormFailure,

} from './supplierActions';
import history from '../utils/history';

const {
    GET_SUPPLIERS_LIST_REQUEST,
    GET_TOP_SUPPLIER_CATEGORIES_REQUEST,
    GET_SUB_SUPPLIER_CATEGORIES_REQUEST,
    SEND_USE_SUPPLIER_FORM_REQUEST,
} = require('./supplierActions').constants;

function* getSuppliersList({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'supplier/list',
            params: payload,
        });

        yield put(getSuppliersListSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getSuppliersListFailure(e.response ? e.response.data.message : e));
    }
}

function* getTopSupplierCategories({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'category/top-categories',
            params: payload,
        });

        yield put(getTopSupplierCategoriesSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getTopSupplierCategoriesFailure(e.message ? e.message : e));
    }
}

function* getSubSupplierCategories({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'category/subcategories',
            params: payload,
        });

        yield put(getSubSupplierCategoriesSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getSubSupplierCategoriesFailure(e.message ? e.message : e));
    }
}

function* sendUseSupplierForm(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'trading-relationship/create',
            body: actions.payload,
        });
        history.push('/');
        message.success('Supplier request sent');
        yield put(sendUseSupplierFormSuccess(response));
    } catch (e) {
        const msg = (e.response && e.response.data && e.response.data.message) || (e.message ? e.message : e);
        yield put(sendUseSupplierFormFailure(msg));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(GET_SUPPLIERS_LIST_REQUEST, getSuppliersList),
        takeEvery(GET_TOP_SUPPLIER_CATEGORIES_REQUEST, getTopSupplierCategories),
        takeEvery(GET_SUB_SUPPLIER_CATEGORIES_REQUEST, getSubSupplierCategories),
        takeEvery(SEND_USE_SUPPLIER_FORM_REQUEST, sendUseSupplierForm),
    ]);
}
