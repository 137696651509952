import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchApiAuth } from '../utils/api';

import {
    getSuggestionsSuccess,
    getSuggestionsFailure,

    getResultsSuccess,
    getResultsFailure,

    getSupplierSuccess,
    getSupplierFailure,

    getSupplierParentSuccess,
    getSupplierParentFailure,

    getPinsSuccess,
    getPinsFailure,
} from './searchActions';

const {
    GET_SUGGESTIONS_REQUEST,
    GET_RESULTS_REQUEST,
    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_PARENT_REQUEST,
    GET_PINS_REQUEST,
} = require('./searchActions').constants;

function* getSuggestions({ payload }) {
    const queryString = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/supplier/suggestions?${queryString}`,
        });

        yield put(getSuggestionsSuccess(response.data));
    } catch (e) {
        yield put(getSuggestionsFailure(e.response ? e.response.data : e));
    }
}

function* getResults({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/supplier/search',
            params: payload,
        });

        yield put(getResultsSuccess(response.data));
    } catch (e) {
        yield put(getResultsFailure(e.response ? e.response.data : e));
    }
}

function* getSupplier({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/supplier/${payload.id}/info`,
            params: payload.params,
        });

        yield put(getSupplierSuccess(response.data));
    } catch (e) {
        yield put(getSupplierFailure(e.response ? e.response.data : e));
    }
}

function* getSupplierParent({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/supplier/${payload.id}/info`,
            params: payload.params,
        });

        yield put(getSupplierParentSuccess(response.data));
    } catch (e) {
        yield put(getSupplierParentFailure(e.response ? e.response.data : e));
    }
}

function* getPins({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/supplier/pins',
            params: payload,
        });

        yield put(getPinsSuccess(response.data));
    } catch (e) {
        yield put(getPinsFailure(e.response ? e.response.data : e));
    }
}

export default function* gallerySaga() {
    yield* [
        takeEvery(GET_SUGGESTIONS_REQUEST, getSuggestions),
        takeEvery(GET_RESULTS_REQUEST, getResults),
        takeEvery(GET_SUPPLIER_REQUEST, getSupplier),
        takeEvery(GET_SUPPLIER_PARENT_REQUEST, getSupplierParent),
        takeEvery(GET_PINS_REQUEST, getPins),
    ];
}
