import { Record } from 'immutable';

const {
    CREATE_CHAT_REQUEST,
    CREATE_CHAT_SUCCESS,
    CREATE_CHAT_FAILURE,
} = require('./chatActions').constants;

const InitialState = Record({
    isFetching: false,
    chatLog: '',
});

const initialState = new InitialState();

export default function globalReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case CREATE_CHAT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case CREATE_CHAT_SUCCESS: {
        return state.set('isFetching', false);
    }

    case CREATE_CHAT_FAILURE: {
        return state.set('isFetching', false)
            .set('error', payload);
    }

    default:
        return state;
    }

}
