import React from 'react';
import { Row, Col, Button, Select, Popover } from 'antd';
import { find } from 'underscore';

import Icon from '../../elements/Icon';
import './style.scss';

const renderApprovalPopover = (result, user) => {
    const { userData } = user;
    let companyName = null;
    const company = find(userData.available_companies, c => c.id === userData.active_company_id);
    if (company) {
        companyName = company.name;
    }

    if (result.approved) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom c_green">
                        <div className="popover__header">
                            <Icon name="checkmark" />
                            <h4 className="h-4">Approved for {companyName}</h4>
                        </div>
                        <p>This supplier has been approved by wsh and can be used immediately without completing a new supplier form</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="checkmark" className="c_green" />
            </Popover>

        );
    }
    if (result.is_global) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom c_blue">
                        <div className="popover__header">
                            <Icon name="checkmark" />
                            <h4 className="h-4">Approved for all companies</h4>
                        </div>
                        <p>This supplier has been approved by wsh and can be used immediately without completing a new supplier form</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="checkmark" className="c_blue" />
            </Popover>
        );
    }
    return (
        ''
    );
};

export default function () {
    const { results, resultCount, user, groupBy } = this.props;
    return (
        <div className="sidebar-results" onScroll={this.handleResultsScroll}>
            <div className="results__header">
                <span className="result__count">
                    <h3 className="h-3">{resultCount} Results</h3>
                    <Button className="close-sidebar-results" onClick={this.handleSidebarClose}>
                        <Icon name="map" />
                        <span style={{ marginLeft: 10 }}>Map</span>
                    </Button>
                </span>
                <Row type="flex" align="middle" gutter={24} className="mt-2">
                    <Col span={12}>
                        <div className="select-label">Sort by</div>
                        <Select
                            onChange={this.handleSortResults}
                            size="large"
                            defaultValue="proximity"
                            className="w100"
                            suffixIcon={<Icon name="chevron-down" />}>
                            <Select.Option value="recommended">Recommended</Select.Option>
                            <Select.Option value="proximity">Proximity</Select.Option>
                            <Select.Option value="supplier">Supplier</Select.Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        {user.userData.role.name === 'Supply Chain Manager' ? (
                            <>
                                <div className="select-label">Pin grouping</div>
                                <Select
                                    defaultValue={null}
                                    value={groupBy}
                                    size="large"
                                    className="w100"
                                    suffixIcon={<Icon name="chevron-down" />}
                                    onChange={this.handlePinGroupChange}>
                                    <Select.Option value={null}>-</Select.Option>
                                    <Select.Option value="recommended">By Recommended</Select.Option>
                                    <Select.Option value="proximity">By Proximity</Select.Option>
                                    <Select.Option value="supplier">By Supplier</Select.Option>
                                </Select>
                            </>
                        ) : false}
                    </Col>
                </Row>
            </div>
            <div className="results__body">

                {results.map((result, key) => (
                    <div className="results__item" key={result.id}>
                        <div className="item__title">
                            <h4 className="h-4">{result.name}</h4>
                            {renderApprovalPopover(result, user)}
                        </div>
                        <Row type="flex" align="middle" gutter={40} className="mt-2">
                            <Col>
                                <span className="item__info">
                                    <Icon name="location" />
                                    <span>{result.distance !== null ? result.distance.toString().slice(0, 4) : ''} KM</span>
                                </span>
                            </Col>
                            <Col>
                                <a href={`tel:${result.phone}`} className="link">{result.phone}</a>
                            </Col>
                        </Row>

                        { this.renderInfoDescription(key, result) }

                        <Row type="flex" align="middle" gutter={12} className="mt-2">
                            <Col span={8}>
                                <a href={`/use-existing-supplier?supplier=${result.id}`}><Button className="btn primary">Use Supplier</Button></a>
                            </Col>
                            <Col span={8}>
                                <a href={result.website}><Button disabled={!result.website} className="btn secondary">Visit Website</Button></a>
                            </Col>
                            <Col span={8}>
                                <Button onClick={() => this.props.getSupplierDetails(result.id, result.parent_id || null)} className="btn iconned">More info</Button>
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </div>
    );
}
