import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { message } from 'antd';
import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';

import DOM from './reportFoodScare';

class ReportFoodScare extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            fileList: [],
            complainant_consent: null,
            caused_injury: null,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getRelatesToOptionsRequest();
        actions.getComplaintTypeOptionsRequest();
    }

    // Handle before upload
    beforeUpload = (file) => {

        // Check file size is below 5 000 000 bytes (5 MB)
        if (file.size > 5 * (10 ** 6)) {
            return false;
        }

        return true;
    }

    onUploadRemove = (fileReference) => {
        const { fileList } = fileReference;
        this.setState({ fileList });
        return true;
    }

    onUploadChange = (fileReference) => {
        const PERMITTED_FILE_TYPES = [
            'application/zip',
            'image/jpeg',
            'image/png',
            'text/plain',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];

        if (!PERMITTED_FILE_TYPES.includes(fileReference.file.type)) {
            message.error('Files of that type are not permitted.');
            return false;
        }

        const fileList = [...fileReference.fileList];

        const getFileSizeString = (filesize) => {
            const labels = [
                'Bytes',
                'KB',
                'MB',
                'GB',
                'TB',
            ];
            for (let i = 0; i < labels.length; i++) {
                if (filesize < 10 ** ((i + 1) * 3)) {
                    const correctedFileSize = filesize / 10 ** (i * 3);
                    const decimalsRemoved = Math.round(correctedFileSize * 100) / 100;
                    const label = labels[i];
                    return `(${decimalsRemoved} ${label})`;
                }
            }
            return `(${filesize} Bytes)`;
        };

        const renamedFiles = fileList.map(file => {
            const newFile = file;
            const fileName = newFile.name;
            const fileSizeString = getFileSizeString(newFile.size);
            const dotIndex = fileName.lastIndexOf('.');
            const justFileName = fileName.substring(0, dotIndex);
            const justExtension = fileName.substring(dotIndex);
            if (!justFileName.endsWith(fileSizeString)) {
                newFile.name = `${justFileName} ${fileSizeString}${justExtension}`;
            }
            return newFile;
        });

        this.setState({ fileList: renamedFiles });
        return true;
    }

    submitForm = (vals) => {
        const { actions } = this.props;
        const { fileList } = this.state;
        const rawFilenames = fileList.map(file => (file.response && file.response.id) || null);
        const filenames = rawFilenames.filter(fileName => fileName !== null);
        const finalValues = {
            ...vals,
            file_ids: filenames,
            date_received: vals.date_received
                ? vals.date_received.format('YYYY-MM-DD HH:mm:ss')
                : vals.date_received,
        };
        actions.sendComplaintFormRequest(finalValues);
    }

    downloadFile(file) {
        const { actions } = this.props;
        if (file && file.response && file.response.id) {
            actions.downloadFileRequest({ file_id: file.response.id });
        }
    }

    render() {
        return this.view();
    }
}

ReportFoodScare.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        relatesToOptions: state.report.relatesToOptions,
        complaintTypeOptions: state.report.complaintTypeOptions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportFoodScare);
