import React from 'react';
import { Input, AutoComplete, Select } from 'antd';
import { TagOutlined, ShopOutlined } from '@ant-design/icons';
import { isNull } from 'underscore';

import Icon from '../../elements/Icon';
import './style.scss';

const { Option } = AutoComplete;

export default function () {
    const { categories, suggestions } = this.props;
    const { subcategories, categorySelected, subcategorySelected } = this.state;

    function getOptions(dataSource) {
        if (dataSource) {
            const options = dataSource.map((opt) => (
                <Option key={opt.name} value={opt.name} className="option">
                    <span className="option__title">{opt.name}</span>
                </Option>
            ));
            return options;
        }
        return [];
    }

    const getSupplierSuggestions = (suggestions.suppliers && suggestions.suppliers.map((opt) => (
        <Option key={`supplier-${opt}`} value={opt} className="option">
            <span className="option__icon"><ShopOutlined /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];

    const getTagSuggestions = (suggestions.tags && suggestions.tags.map((opt) => (
        <Option style={{ backgroundColor: 'lightblue' }} key={`tag-${opt}`} value={opt} className="option">
            <span className="option__icon"><TagOutlined /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];

    const uniqueSearchHistory = suggestions.search_history && [...new Set(suggestions.search_history)];
    const getPrevSearchSuggestions = (suggestions.search_history && uniqueSearchHistory.map((opt) => (
        <Option key={`search-${opt}`} value={opt} className="option">
            <span className="option__icon"><Icon name="search" /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];

    const getAllSuggestions = [...getPrevSearchSuggestions, ...getSupplierSuggestions, ...getTagSuggestions];

    return (
        <div
            className="searchbar"
            onKeyDownCapture={this.searchKeyDown}>
            <Select
                className="category-dropdown-search"
                placeholder="All Categories"
                size="large"
                style={{ pointerEvents: 'all', minWidth: '22.5%', marginRight: '5px' }}
                onChange={this.handleCategoryInput}
                value={categorySelected}
                suffixIcon={<Icon name="chevron-down" />}>
                {getOptions([{ name: 'All Categories' }, ...categories.filter(c => isNull(c.parent_id))])}
            </Select>
            {categorySelected !== undefined && categorySelected !== '' && categorySelected !== 'All Categories' ? (
                <Select
                    placeholder="All Subcategories"
                    size="large"
                    style={{ pointerEvents: 'all' }}
                    onChange={this.handleSubcategoryInput}
                    value={subcategorySelected}
                    suffixIcon={<Icon name="chevron-down" />}>
                    {getOptions([{ name: 'All Subcategories' }, ...subcategories])}
                </Select>
            ) : ''}
            <span className="separator-vert" />
            <AutoComplete
                className="certain-category-search"
                dropdownClassName="search__dropdown"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                size="large"
                style={{ pointerEvents: 'all' }}
                onChange={this.handleSearchInputChange}
                dataSource={getAllSuggestions}
                placeholder="Type here"
                value={this.state.searchTerm}
                optionLabelProp="value">
                <Input.Search style={{ pointerEvents: 'all' }} onSearch={this.handleSearchSubmit} />
            </AutoComplete>
            <span className="separator-vert" />
            <button onClick={this.handleResetSearch} type="button" className="btn transparent" style={{ pointerEvents: 'all' }}>
                <Icon name="close" />
            </button>
        </div>
    );
}
