import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './style.scss';
import * as userActions from '../../core/user/userActions';

const {
    API_URL,
    VINE_DASHBOARD_URL,
} = require('../../core/constants').default;

/**
 * This page allows the user to click a button to go back to vine. It should be
 * a publicly available URL
 */
class VineError extends Component {

    componentDidMount() {
        const { actions } = this.props;
        actions.deleteTokenRequest();
    }

    render() {
        return (
            <div className="vine-error-container">
                <div className="centered-box">
                    <h2>You&apos;ve been logged out</h2>
                    <div>
                        <Button
                            type="secondary"
                            href={VINE_DASHBOARD_URL}>
                            Vine dashboard
                        </Button>
                        <Button
                            type="primary"
                            href={`${API_URL}/saml2/vine/login`}>
                            Reauthenticate
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

VineError.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VineError);
