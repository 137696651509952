import React, { Component } from 'react';
import { Button } from 'antd';

import './style.scss';

const {
    VINE_DASHBOARD_URL,
} = require('../../core/constants').default;

/**
 * This page allows the user to click a button to go back to vine. It should be
 * a publicly available URL
 */
class VineError extends Component {

    render() {
        return (
            <div className="vine-error-container">
                <div className="centered-box">
                    <h2>Something went wrong</h2>
                    <Button
                        type="primary"
                        href={VINE_DASHBOARD_URL}>
                        Go back vine
                    </Button>
                </div>
            </div>
        );
    }
}

export default VineError;
