import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as faqActions from '../../core/faq/faqActions';

import DOM from './Faq';

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.filteredFaqList = [];
        this.view = DOM;
        this.searchCategorySelect = React.createRef();
        this.searchInput = React.createRef();
        this.state = {
            searchTerm: null,
            selectedCategory: null,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getFaqDataRequest();
        actions.getFaqCategoriesRequest();
        this.filteredFaqList = this.props.faqList;
    }

    componentDidUpdate() {
        this.filterFaqList();
    }

    handleCategoryInput = (value) => {
        this.setState({ selectedCategory: value });

        this.filterFaqList(value);
    }

    handleSearchInput = (event) => {
        this.setState({ searchTerm: event.target.value });

        this.filterFaqList();
    }

    handleResetSearch = () => {
        this.setState({
            searchTerm: null,
            selectedCategory: null,
        });

        this.filterFaqList(null, null);
    }

    filterFaqList = (categoryId = this.state.selectedCategory, searchTerm = this.state.searchTerm) => {
        this.filteredFaqList = this.props.faqList.filter(faq => {
            if ((searchTerm ? faq.question.toLowerCase().includes(searchTerm.toLowerCase()) || faq.answer.toLowerCase().includes(searchTerm.toLowerCase()) : true) && (categoryId ? faq.faq_category_id === categoryId : true)) {
                return true;
            }

            return false;
        });
    }

    render() {
        return this.view(this.props);
    }
}

Faq.propTypes = {
    actions: PropTypes.object.isRequired,
    faqList: PropTypes.array.isRequired,
    faqCategories: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        faqList: state.faq.faqList,
        faqCategories: state.faq.faqCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...faqActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
