import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './header';

import * as helpActions from '../../../core/help/helpActions';
import * as userActions from '../../../core/user/userActions';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileDrawerOpen: false,
            profileCollapsed: true,
        };
        this.view = DOM;
    }

    openProfileDrawer = () => {
        this.setState({ profileDrawerOpen: true });
    }

    closeProfileDrawer = () => {
        this.setState({ profileDrawerOpen: false });
    }

    toggleProfile = () => {
        const { profileCollapsed } = this.state;
        this.setState({ profileCollapsed: !profileCollapsed });
    }

    updateCompany = (companyId) => {
        const { actions } = this.props;
        actions.updateActiveCompanyRequest({ company_id: companyId });
    }

    render() {
        return this.view(this.props);
    }
}

Header.defaultProps = {
    toolbar: [],
    menu: [],
    showMenu: false,
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 400,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    showHelp: false,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    showMenu: PropTypes.bool,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    showHelp: PropTypes.bool,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user.userData,
        helpModalOpen: state.help.modalOpen,
        userCompanies: state.user.userData && state.user.userData.available_companies ? state.user.userData.available_companies : [],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...helpActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
