import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getParentCompaniesListSuccess,
    getParentCompaniesListFailure,

    getChildCompanyListSuccess,
    getChildCompanyListFailure,

    updateCompanySuccess,
    updateCompanyFailure,

} from './companyActions';

const {
    GET_PARENT_COMPANIES_LIST_REQUEST,
    GET_CHILD_COMPANY_LIST_REQUEST,
    UPDATE_COMPANY_REQUEST,
} = require('./companyActions').constants;

function* getParentCompaniesList() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'company/parent-companies',
        });

        yield put(getParentCompaniesListSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getParentCompaniesListFailure(e.response ? e.response.data.message : e));
    }
}

function* getChildCompanyList(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/child-companies?company_id=${actions.payload.company_id}`,
        });

        yield put(getChildCompanyListSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getChildCompanyListFailure(e.message ? e.message : e));
    }
}

function* updateCompany(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'company/update-return-list',
            body: actions.payload,
        });

        message.success('Updated location details');
        yield put(updateCompanySuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(updateCompanyFailure(e.message ? e.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeLatest(GET_PARENT_COMPANIES_LIST_REQUEST, getParentCompaniesList),
        takeEvery(GET_CHILD_COMPANY_LIST_REQUEST, getChildCompanyList),
        takeEvery(UPDATE_COMPANY_REQUEST, updateCompany),
    ]);
}
