import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DOM from './chat';

import * as userActions from '../../core/user/userActions';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.desktopChatBotWidthCutOff = 575;
        this.chatBotPercentage = 0.38;
        this.state = { windowWidth: (window.innerWidth > this.desktopChatBotWidthCutOff) ? window.innerWidth * this.chatBotPercentage : window.innerWidth,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleChatBotResize);
    }

    handleChatBotResize = () => {
        if (window.innerWidth > this.desktopChatBotWidthCutOff) {
            if (window.innerWidth * this.chatBotPercentage >= this.desktopChatBotWidthCutOff) {
                this.setState({ windowWidth: window.innerWidth * this.chatBotPercentage,
                });
            } else {
                this.setState({ windowWidth: this.desktopChatBotWidthCutOff,
                });
            }
        } else {
            this.setState({ windowWidth: window.innerWidth,
            });
        }
    };

    render() {
        return this.view(this.props);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userAvatar: state.user.userData.profile_url,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
