import { call, put, takeEvery } from 'redux-saga/effects';

import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    createChatSuccess,
    createChatFailure,
} from './chatActions';

const {
    CREATE_CHAT_REQUEST,
} = require('./chatActions').constants;

function* createChat({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/supplier-request/create',
            body: payload,
        });

        yield put(createChatSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(createChatFailure(e.message ? e.message : e));
    }
}

export default function* gallerySaga() {
    yield* [
        takeEvery(CREATE_CHAT_REQUEST, createChat),
    ];
}
