/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_RELATES_TO_OPTIONS_REQUEST,
    GET_RELATES_TO_OPTIONS_SUCCESS,
    GET_RELATES_TO_OPTIONS_FAILURE,

    GET_COMPLAINT_TYPE_OPTIONS_REQUEST,
    GET_COMPLAINT_TYPE_OPTIONS_SUCCESS,
    GET_COMPLAINT_TYPE_OPTIONS_FAILURE,

    SEND_COMPLAINT_FORM_REQUEST,
    SEND_COMPLAINT_FORM_SUCCESS,
    SEND_COMPLAINT_FORM_FAILURE,

    DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_FAILURE,

} = require('./reportActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    relatesToOptions: null,
    complaintTypeOptions: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case GET_RELATES_TO_OPTIONS_REQUEST:
    case GET_COMPLAINT_TYPE_OPTIONS_REQUEST:
    case SEND_COMPLAINT_FORM_REQUEST:
    case DOWNLOAD_FILE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_RELATES_TO_OPTIONS_SUCCESS:
        return state.set('relatesToOptions', payload.data).set('isFetching', false);
    case GET_COMPLAINT_TYPE_OPTIONS_SUCCESS:
        return state.set('complaintTypeOptions', payload.data).set('isFetching', false);
    case SEND_COMPLAINT_FORM_SUCCESS:
    case DOWNLOAD_FILE_SUCCESS:
        return state.set('isFetching', false);


    case GET_RELATES_TO_OPTIONS_FAILURE:
    case GET_COMPLAINT_TYPE_OPTIONS_FAILURE:
    case SEND_COMPLAINT_FORM_FAILURE:
    case DOWNLOAD_FILE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
