import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import * as globalActions from '../../core/global/globalActions';
import * as searchActions from '../../core/search/searchActions';

import DOM from './supplierDetails';

class SupplierDetails extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            visible: false,
            showParentDetails: false,
            firstComponentUpdateHappened: false,
            cutOffToggle: true,
            ref: React.createRef(),
            cutOffHeight: 80,
        };
    }

    componentDidUpdate() {
        const { supplier } = this.props;
        if (!this.state.firstComponentUpdateHappened) {
            if (supplier.parent_id !== null) {
                this.setState({ showParentDetails: true });
            }
            this.setState({ firstComponentUpdateHappened: true });
        }
    }

    closeModal = () => {
        this.setState({ visible: false });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    toggleParentDetails = (parentId) => {
        const { actions, searchParams: { latitude, longitude } } = this.props;
        if (!this.state.showParentDetails) {
            this.setState({ showParentDetails: true });
            actions.getSupplierParentRequest({ id: parentId, params: { latitude, longitude } });
        } else {
            this.setState({ showParentDetails: false });
        }
    }

    // Triggered by readOption click.
    handleAboutResize = () => {
        this.setState((prevState) => ({ cutOffToggle: !prevState.cutOffToggle }));
    }

    formatCategories(categories) {
        let i = 0;
        const results = [];
        const listSize = categories.length;
        // eslint-disable-next-line array-callback-return
        categories.map(category => {
            i++;
            if (i < listSize) {
                results.push(`${category.name}, `);
            } else {
                results.push(category.name);
            }
        });
        return results;
    }

    formatTags(tags) {
        let i = 0;
        const results = [];
        const listSize = tags.length;
        // eslint-disable-next-line array-callback-return
        tags.map(tag => {
            i++;
            if (i < listSize) {
                results.push(`${tag}, `);
            } else {
                results.push(tag);
            }
        });
        return results;
    }

    // Triggered as part of render method.
    renderAbout(supplierDetails) {
        let style = null;
        let renderViewOption = null;
        // Protection against unassigned ref
        if (this.state.ref.current) {
            style = {
                maxHeight: (this.state.cutOffToggle === true) ? `${this.state.cutOffHeight}px` : null,
                minHeight: (this.state.cutOffToggle === true) ? `${this.state.cutOffHeight}px` : `${this.state.ref.current.scrollHeight}px`,
            };
            if (this.state.ref.current.offsetHeight >= this.state.cutOffHeight) {
                renderViewOption = true;
            } else {
                style = {
                    maxHeight: null,
                    minHeight: null,
                };
                renderViewOption = false;
            }
        } else {
            style = {
                maxHeight: (this.state.cutOffToggle === true) ? `${this.state.cutOffHeight}px` : null,
                overflowY: (this.state.cutOffToggle === true) ? 'hidden' : 'auto',
            };
        }
        return (
            <div>
                <div className="info__description" style={style} ref={this.state.ref}>
                    <p>
                        {supplierDetails.description !== undefined ? supplierDetails.description : ''}
                    </p>
                </div>
                { (renderViewOption === true)
                    ? (
                        <div className="viewOptionContainer">
                            <Button className="viewOption" onClick={() => this.handleAboutResize()}>{ (this.state.cutOffToggle === true) ? 'view more' : 'view less'}</Button>
                        </div>
                    )
                    : null }
            </div>
        );
    }

    render() {
        return this.view();
    }
}

SupplierDetails.defaultProps = {
    supplierParent: null,
};

SupplierDetails.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    supplier: PropTypes.object.isRequired,
    supplierParent: PropTypes.object,
    searchParams: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        supplier: state.search.supplier,
        supplierParent: state.search.supplierParent,
        searchParams: state.search.searchParams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...searchActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetails);
