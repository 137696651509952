import React from 'react';
import {
    Input,
    Button,
    Row,
    Col,
    Select,
} from 'antd';

import './style.scss';
import Icon from '@ant-design/icons/lib/components/Icon';

export default function () {
    const { editing } = this.state;
    const {
        isFetching,
        topCompanies,
        subCompanies,
        selectedTopCompany,
        selectedSubCompany,
        subCompanyInfo,
    } = this.props;

    const shouldShowChild = selectedTopCompany !== null && !(editing && selectedSubCompany === null && selectedTopCompany === selectedSubCompany);
    const shouldShowInfo = selectedSubCompany !== null && !editing;
    const shouldShowEdit = editing;

    const subCompanyInfoAddress = subCompanyInfo.address || {};
    return (
        <div>
            <div className="mt-3">
                Trading Company
                <Select
                    value={selectedTopCompany}
                    disabled={shouldShowEdit}
                    loading={isFetching}
                    placeholder="Select company"
                    size="large"
                    suffixIcon={<Icon name="chevron-down" />}
                    onChange={this.topCompanyChange}>
                    {topCompanies.map((comp) => (
                        <Select.Option key={comp.id} value={comp.id}>{comp.title}</Select.Option>
                    ))}
                </Select>
            </div>
            {shouldShowChild
                ? (
                    <Row type="flex" align="middle" gutter={16} className="mt-2">
                        <Col className="flgrow">
                            Location number
                            <Select
                                value={selectedSubCompany}
                                disabled={shouldShowEdit}
                                loading={isFetching}
                                placeholder="Select location"
                                size="large"
                                suffixIcon={<Icon name="chevron-down" />}
                                onChange={this.subCompanyChange}>
                                {subCompanies.map((comp) => (
                                    <Select.Option key={comp.id} value={comp.id}>{comp.title}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        {shouldShowEdit ? null : (
                            <Col>
                                <Button onClick={this.createButtonClicked} className="btn underlined">Add new</Button>
                            </Col>
                        )}
                    </Row>
                )
                : null}
            {shouldShowInfo
                ? (
                    <>
                        <div className="mt-3">
                            <h5 className="h-5">Location details (for Location #{subCompanyInfo.location_number})</h5>
                            <ul className="list">
                                <li className="list__item">
                                    <div className="h-5">Location name</div>
                                    <div>{subCompanyInfo.title}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Sub account number:</div>
                                    <div>{subCompanyInfo.sub_account_number}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Building name:</div>
                                    <div>{subCompanyInfo.location_name}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Address 1:</div>
                                    <div>{subCompanyInfoAddress.address_1}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Address 2:</div>
                                    <div>{subCompanyInfoAddress.address_2}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Town:</div>
                                    <div>{subCompanyInfoAddress.town}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">County:</div>
                                    <div>{subCompanyInfoAddress.county}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Postcode:</div>
                                    <div>{subCompanyInfoAddress.postcode}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Country:</div>
                                    <div>{subCompanyInfoAddress.country}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Location telephone:</div>
                                    <div>{subCompanyInfo.telephone}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Location email:</div>
                                    <div>{subCompanyInfo.email}</div>
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Delivery instructions:</div>
                                    <div>{subCompanyInfo.delivery_instructions}</div>
                                </li>

                            </ul>
                        </div>
                        <div className="mt-3">
                            <Button onClick={this.editButtonClicked} className="btn underlined">Change</Button>
                        </div>
                    </>
                )
                : null}
            {shouldShowEdit
                ? (
                    <>
                        <div className="mt-3">
                            <h5 className="h-5">{selectedSubCompany && subCompanyInfo.location_number ? `Location details (for Location${subCompanyInfo.location_number})` : 'Creating new location'}</h5>
                            <ul className="list">
                                {
                                    selectedSubCompany
                                        ? null
                                        : (
                                            <li className="list__item">
                                                <div className="h-5">Location number</div>
                                                <Input
                                                    onChange={(val) => this.setState({ location_number: val.target.value })}
                                                    value={this.state.location_number}
                                                    defaultValue={subCompanyInfo.location_number} />
                                            </li>
                                        )
                                }

                                <li className="list__item">
                                    <div className="h-5">Location name<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ title: val.target.value })}
                                        value={this.state.title}
                                        defaultValue={subCompanyInfo.title} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Sub account number:</div>
                                    <Input
                                        onChange={(val) => this.setState({ sub_account_number: val.target.value })}
                                        value={this.state.sub_account_number}
                                        defaultValue={subCompanyInfo.sub_account_number} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Building name<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ location_name: val.target.value })}
                                        value={this.state.location_name}
                                        defaultValue={subCompanyInfo.location_name} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Address 1<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ address_1: val.target.value })}
                                        value={this.state.address_1}
                                        defaultValue={subCompanyInfoAddress.address_1} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Address 2:</div>
                                    <Input
                                        onChange={(val) => this.setState({ address_2: val.target.value })}
                                        value={this.state.address_2}
                                        defaultValue={subCompanyInfoAddress.address_2} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Town:</div>
                                    <Input
                                        onChange={(val) => this.setState({ town: val.target.value })}
                                        value={this.state.town}
                                        defaultValue={subCompanyInfoAddress.town} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">County:</div>
                                    <Input
                                        onChange={(val) => this.setState({ county: val.target.value })}
                                        value={this.state.county}
                                        defaultValue={subCompanyInfoAddress.county} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Postcode<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ postcode: val.target.value })}
                                        value={this.state.postcode}
                                        defaultValue={subCompanyInfoAddress.postcode} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Country:</div>
                                    <Input
                                        onChange={(val) => this.setState({ country: val.target.value })}
                                        value={this.state.country}
                                        defaultValue={subCompanyInfoAddress.country} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Location telephone<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ telephone: val.target.value })}
                                        value={this.state.telephone}
                                        defaultValue={subCompanyInfo.telephone} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Location email<span className="required_indicator">*</span>:</div>
                                    <Input
                                        onChange={(val) => this.setState({ email: val.target.value })}
                                        value={this.state.email}
                                        defaultValue={subCompanyInfo.email} />
                                </li>
                                <li className="list__item">
                                    <div className="h-5">Delivery instructions:</div>
                                    <Input
                                        onChange={(val) => this.setState({ delivery_instructions: val.target.value })}
                                        value={this.state.delivery_instructions}
                                        defaultValue={subCompanyInfo.delivery_instructions} />
                                </li>

                            </ul>
                        </div>
                        <div className="mt-3">
                            <Button onClick={this.saveButtonClicked} className="btn underlined">{selectedSubCompany ? 'Update' : 'Create'}</Button>
                        </div>
                        <div className="mt-3">
                            <Button onClick={this.backButtonClicked} className="btn underlined">Back</Button>
                        </div>
                    </>
                )
                : null}
        </div>
    );
}
