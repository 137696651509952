import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    SEND_HELP_REQUEST_FORM_REQUEST: null,
    SEND_HELP_REQUEST_FORM_SUCCESS: null,
    SEND_HELP_REQUEST_FORM_FAILURE: null,

    OPEN_HELP_MODAL: null,
    CLOSE_HELP_MODAL: null,
});

export const {
    sendHelpRequestFormRequest,
    sendHelpRequestFormSuccess,
    sendHelpRequestFormFailure,
} = createActions(
    constants.SEND_HELP_REQUEST_FORM_REQUEST,
    constants.SEND_HELP_REQUEST_FORM_SUCCESS,
    constants.SEND_HELP_REQUEST_FORM_FAILURE,
);

export const {
    openHelpModal,
    closeHelpModal,
} = createActions(
    constants.OPEN_HELP_MODAL,
    constants.CLOSE_HELP_MODAL,
);
