import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_REQUESTS_LIST_REQUEST: null,
    GET_REQUESTS_LIST_SUCCESS: null,
    GET_REQUESTS_LIST_FAILURE: null,

});

export const {
    getRequestsListRequest,
    getRequestsListSuccess,
    getRequestsListFailure,
} = createActions(
    constants.GET_REQUESTS_LIST_REQUEST,
    constants.GET_REQUESTS_LIST_SUCCESS,
    constants.GET_REQUESTS_LIST_FAILURE,
);
