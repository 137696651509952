import { Record } from 'immutable';
import moment from 'moment';

const {
    DELETE_TOKEN_REQUEST,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    GET_USER_DATA_REQUEST,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,

    UPDATE_ACTIVE_COMPANY_REQUEST,
    UPDATE_ACTIVE_COMPANY_SUCCESS,
    UPDATE_ACTIVE_COMPANY_FAILURE,

    UPDATE_LOCATION_REQUEST,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_FAILURE,

    SET_LOCATION,
    CLEAR_USER_DATA,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    userSettings: {},
    userData: {},
    userFetchedAt: null,
    mapLocation: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);
    switch (type) {
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case GET_USER_DATA_REQUEST:
    case UPDATE_LOCATION_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_REQUEST:
        return state.set('isFetching', true)
            .set('userFetchedAt', null)
            .set('error', null)
            .set('loginUser', '')
            .set('userSettings', {})
            .set('userData', {})
            .set('mapLocation', null)
            .set('isLoggedIn', false);

    case UPDATE_ACTIVE_COMPANY_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('userFetchedAt', moment())
            .set('loginUser', (payload && payload.data && payload.data.username ? payload.data.username : 'Logged In User'))
            .set('userSettings', (payload && payload.data && payload.data.settings ? payload.data.settings : {}))
            .set('isLoggedIn', true);

    case DELETE_TOKEN_REQUEST:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('userFetchedAt', null)
            .set('error', null)
            .set('loginUser', '')
            .set('userSettings', {})
            .set('userData', {})
            .set('mapLocation', null)
            .set('isLoggedIn', false);

    case GET_USER_DATA_SUCCESS:
    case UPDATE_ACTIVE_COMPANY_SUCCESS:
    case UPDATE_LOCATION_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', payload.data);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    case GET_USER_DATA_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case UPDATE_ACTIVE_COMPANY_FAILURE:
    case UPDATE_LOCATION_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SET_LOCATION:
        return state.set('mapLocation', payload);

    case CLEAR_USER_DATA:
        return state.set('userFetchedAt', null)
            .set('error', null)
            .set('loginUser', '')
            .set('userSettings', {})
            .set('userData', {})
            .set('mapLocation', null)
            .set('isLoggedIn', false);

    default:
        return state;

    }
}
