/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_FAQ_DATA_REQUEST,
    GET_FAQ_DATA_SUCCESS,
    GET_FAQ_DATA_FAILURE,

    GET_FAQ_CATEGORIES_REQUEST,
    GET_FAQ_CATEGORIES_SUCCESS,
    GET_FAQ_CATEGORIES_FAILURE,
} = require('./faqActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    faqList: [],
    faqCategories: [],
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function faqReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case GET_FAQ_DATA_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_FAQ_DATA_SUCCESS:
        return state.set('faqList', payload)
            .set('isFetching', false)
            .set('error', null);

    case GET_FAQ_DATA_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_FAQ_CATEGORIES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_FAQ_CATEGORIES_SUCCESS:
        return state.set('faqCategories', payload)
            .set('isFetching', false)
            .set('error', null);

    case GET_FAQ_CATEGORIES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
