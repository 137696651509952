import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_RELATES_TO_OPTIONS_REQUEST: null,
    GET_RELATES_TO_OPTIONS_SUCCESS: null,
    GET_RELATES_TO_OPTIONS_FAILURE: null,

    GET_COMPLAINT_TYPE_OPTIONS_REQUEST: null,
    GET_COMPLAINT_TYPE_OPTIONS_SUCCESS: null,
    GET_COMPLAINT_TYPE_OPTIONS_FAILURE: null,

    SEND_COMPLAINT_FORM_REQUEST: null,
    SEND_COMPLAINT_FORM_SUCCESS: null,
    SEND_COMPLAINT_FORM_FAILURE: null,

    DOWNLOAD_FILE_REQUEST: null,
    DOWNLOAD_FILE_SUCCESS: null,
    DOWNLOAD_FILE_FAILURE: null,

});

export const {
    getRelatesToOptionsRequest,
    getRelatesToOptionsSuccess,
    getRelatesToOptionsFailure,
} = createActions(
    constants.GET_RELATES_TO_OPTIONS_REQUEST,
    constants.GET_RELATES_TO_OPTIONS_SUCCESS,
    constants.GET_RELATES_TO_OPTIONS_FAILURE,
);

export const {
    getComplaintTypeOptionsRequest,
    getComplaintTypeOptionsSuccess,
    getComplaintTypeOptionsFailure,
} = createActions(
    constants.GET_COMPLAINT_TYPE_OPTIONS_REQUEST,
    constants.GET_COMPLAINT_TYPE_OPTIONS_SUCCESS,
    constants.GET_COMPLAINT_TYPE_OPTIONS_FAILURE,
);

export const {
    sendComplaintFormRequest,
    sendComplaintFormSuccess,
    sendComplaintFormFailure,
} = createActions(
    constants.SEND_COMPLAINT_FORM_REQUEST,
    constants.SEND_COMPLAINT_FORM_SUCCESS,
    constants.SEND_COMPLAINT_FORM_FAILURE,
);

export const {
    downloadFileRequest,
    downloadFileSuccess,
    downloadFileFailure,
} = createActions(
    constants.DOWNLOAD_FILE_REQUEST,
    constants.DOWNLOAD_FILE_SUCCESS,
    constants.DOWNLOAD_FILE_FAILURE,
);
