import React from 'react';
import { Row, Col, Form, Select, Button, Input, Radio, DatePicker } from 'antd';
import Layout from '../../components/layout/Layout';
import Icon from '../../elements/Icon';
import WhsLogo from '../../assets/images/wsh-logo.svg';

import './style.scss';
import CompanySelector from '../../components/CompanySelector';

export default function () {
    const { suppliers, categories, user } = this.props;
    const { supplier, selectedCategory, selectedSubCategory, supplyStarted } = this.state;
    const backLink = {
        link: '/',
        title: 'Use Existing Supplier',
    };

    const selectedSupplier = suppliers.find((s) => `${s.id}` === `${supplier}`) || { id: null, name: 'Unknown' };

    return (
        <Layout title={<div>Welcome</div>} showMenu backLink={backLink} headerTitle={<div><img src={WhsLogo} alt="logo" /></div>}>
            <div className="content-wrap">
                <div className="content-contr">
                    <Form layout="vertical" ref={this.existingSupplierForm} onFinish={this.sendRequest}>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Supplier selected</h4>
                            </div>
                            <div>
                                <h5 className="h-5">Name</h5>
                                {supplier === null ? (
                                    <Form.Item rules={[{ required: true, message: 'Please select supplier' }]} name="supplier_id">
                                        <Select
                                            size="large"
                                            style={{ minWidth: 150 }}
                                            suffixIcon={<Icon name="chevron-down" />}>
                                            {suppliers.map((c) => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <div className="mt-1 flex-center">
                                        <span>{selectedSupplier.name}</span>
                                        {parseInt(selectedSupplier.company_count, 10) ? (
                                            <Icon name="checkmark" className="c_green ml-2" />
                                        ) : (
                                            <>
                                                {selectedSupplier.global === 1 ? (
                                                    <Icon name="checkmark" className="c_blue ml-2" />
                                                ) : false}
                                            </>
                                        )}

                                    </div>
                                )}

                            </div>
                            <div className="mt-3">
                                <div className="h-5">Primary category of supply</div>
                                <Row type="flex" align="middle" gutter={20} className="mt-2 childs-xs-mt-2">
                                    <Col>
                                        <Select
                                            value={selectedCategory ? selectedCategory.id : null}
                                            size="large"
                                            style={{ minWidth: 150 }}
                                            suffixIcon={<Icon name="chevron-down" />}
                                            onChange={this.categoryChange}>
                                            {categories.map((c) => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
                                        </Select>
                                    </Col>

                                    {selectedCategory ? (
                                        <Col>
                                            <Select
                                                value={selectedSubCategory ? selectedSubCategory.id : null}
                                                onChange={this.subCategoryChange}
                                                size="large"
                                                style={{ minWidth: 150, display: selectedCategory ? 'inherit' : 'none' }}
                                                suffixIcon={<Icon name="chevron-down" />}>
                                                <Select.Option value={selectedCategory ? selectedCategory.id : undefined}>All subcategories</Select.Option>
                                                {selectedCategory.sub_categories ? selectedCategory.sub_categories.map((s) => (<Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)) : ''}
                                            </Select>
                                        </Col>
                                    ) : false}
                                    <Col>
                                        <Button onClick={this.clearCategories} className="btn underlined">Clear</Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-3">
                                <h5 className="h-5">Describe required product or service</h5>
                                <Form.Item rules={[{ required: true, message: 'Please provide a description' }]} className="mt-2" label="A short description, 1-5 keywords" name="description">
                                    <Input size="large" />
                                </Form.Item>
                            </div>
                            <div className="mt-3">
                                <h5 className="h-5">Has supply started?</h5>
                                <Radio.Group
                                    defaultValue={supplyStarted}
                                    onChange={({ target: { value } }) => {
                                        this.setState({ supplyStarted: value });
                                    }}>
                                    <Radio value={0}>No</Radio>
                                    <Radio value={1}>Yes</Radio>
                                </Radio.Group>
                            </div>
                            <div className="mt-3">
                                <h5 className="h-5">{
                                    supplyStarted
                                        ? 'When did supply start?'
                                        : 'When should supply start?'
                                }
                                </h5>
                                <Form.Item rules={[{ required: true, message: 'Please select a date' }]} className="mt-2" name="start_date">
                                    <DatePicker
                                        style={{ width: 323 }}
                                        placeholder=" /   /"
                                        format="DD/MM/YYYY"
                                        size="large"
                                        suffixIcon={<Icon name="calendar" />} />
                                </Form.Item>
                            </div>

                            <div className="mt-3">
                                <h5 className="h-5">
                                    Is there an end date for this request?
                                </h5>
                                <Form.Item className="mt-2" name="end_date">
                                    <DatePicker
                                        style={{ width: 323 }}
                                        placeholder=" /   /"
                                        format="DD/MM/YYYY"
                                        size="large"
                                        suffixIcon={<Icon name="calendar" />} />
                                </Form.Item>
                            </div>

                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Requested By</h4>
                            </div>
                            <div className="mt-3">
                                <Form.Item rules={[{ required: true, message: 'Please input a name' }]} label="Name" name="name">
                                    <Input size="large" defaultValue={user.name} initialvalue={user.name} />
                                </Form.Item>
                                <Form.Item rules={[{ required: true, message: 'Please input a phone number' }]} label="Contact number" name="phone_number">
                                    <Input size="large" defaultValue={user.contact_number} initialvalue={user.contact_number} />
                                </Form.Item>
                                <Form.Item rules={[{ required: true, message: 'Please input an email address' }, { type: 'email' }]} label="Email" name="email">
                                    <Input size="large" defaultValue={user.email} initialvalue={user.email} />
                                </Form.Item>
                                <Form.Item label="Job title" name="job_title">
                                    <Input size="large" defaultValue={user.job_title} initialvalue={user.job_title} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Your Company and Location</h4>
                            </div>
                            <Form.Item rules={[{ required: true, message: 'Please select a branch' }]} name="company_id">
                                <CompanySelector />
                            </Form.Item>
                            <Form.Item>
                                <div className="mt-5 text-center">
                                    <Button className="primary wide" type="primary" htmlType="submit">
                                        Use
                                    </Button>
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
}
