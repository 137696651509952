import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_PARENT_COMPANIES_LIST_REQUEST: null,
    GET_PARENT_COMPANIES_LIST_SUCCESS: null,
    GET_PARENT_COMPANIES_LIST_FAILURE: null,

    GET_CHILD_COMPANY_LIST_REQUEST: null,
    GET_CHILD_COMPANY_LIST_SUCCESS: null,
    GET_CHILD_COMPANY_LIST_FAILURE: null,

    UPDATE_COMPANY_REQUEST: null,
    UPDATE_COMPANY_SUCCESS: null,
    UPDATE_COMPANY_FAILURE: null,

    SELECT_SUB_COMPANY: null,
});

export const {
    getParentCompaniesListRequest,
    getParentCompaniesListSuccess,
    getParentCompaniesListFailure,
} = createActions(
    constants.GET_PARENT_COMPANIES_LIST_REQUEST,
    constants.GET_PARENT_COMPANIES_LIST_SUCCESS,
    constants.GET_PARENT_COMPANIES_LIST_FAILURE,
);

export const {
    getChildCompanyListRequest,
    getChildCompanyListSuccess,
    getChildCompanyListFailure,
} = createActions(
    constants.GET_CHILD_COMPANY_LIST_REQUEST,
    constants.GET_CHILD_COMPANY_LIST_SUCCESS,
    constants.GET_CHILD_COMPANY_LIST_FAILURE,
);

export const {
    updateCompanyRequest,
    updateCompanySuccess,
    updateCompanyFailure,
} = createActions(
    constants.UPDATE_COMPANY_REQUEST,
    constants.UPDATE_COMPANY_SUCCESS,
    constants.UPDATE_COMPANY_FAILURE,
);

export const {
    selectSubCompany,
} = createActions(
    constants.SELECT_SUB_COMPANY,
);
