import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';

import UseExistingSupplier from '../pages/UseExistingSupplier';
import ReportFoodScare from '../pages/ReportFoodScare';
import YourRequests from '../pages/YourRequests';
import Search from '../pages/Search';
import AutoLogin from '../pages/AutoLogin';
import NoCompanyError from '../pages/NoCompanyError';
import VineError from '../pages/VineError';
import VineLogout from '../pages/VineLogout';
import DefaultPage from '../pages/DefaultPage';
import Faq from '../pages/FaqPage';

// Testing contact page
const Contact = () => (<div>Contact Page</div>);

const Routes = ({ persistor }) => (
    <CoreRouter>
        {/* Routes without authentication */}
        <DefaultRoute exact path="/" component={DefaultPage} />
        <DefaultRoute exact path="/auto-login" component={AutoLogin} />
        <DefaultRoute exact path="/no-company-error" component={NoCompanyError} />
        <DefaultRoute exact path="/vine-error" component={VineError} />
        <DefaultRoute exact path="/vine-logout" component={VineLogout} />

        {/* Routes where authentication is required */}
        <AuthRoute exact path="/search" component={Search} />
        <AuthRoute exact path="/use-existing-supplier" component={UseExistingSupplier} />
        <AuthRoute exact path="/report-food-scare" component={ReportFoodScare} />
        <AuthRoute exact path="/your-requests" component={YourRequests} />
        <AuthRoute exact path="/contact" component={Contact} />
        <AuthRoute exact path="/faq" component={Faq} />

        {/* Logout route */}
        <LogoutRoute exact path="/logout" persistor={persistor} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
