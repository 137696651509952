import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as globalActions from '../../core/global/globalActions';
import * as searchActions from '../../core/search/searchActions';

import DOM from './searchResults';

class SearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {}

    render() {
        return this.view();
    }
}

SearchResults.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...searchActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
