/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_REQUESTS_LIST_REQUEST,
    GET_REQUESTS_LIST_SUCCESS,
    GET_REQUESTS_LIST_FAILURE,

} = require('./requestsActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    items: [],
    page: 1,
    total: 0,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case GET_REQUESTS_LIST_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_REQUESTS_LIST_SUCCESS:
        return state.set('isFetching', false)
            .set('items', payload.data.data)
            .set('page', payload.data.current_page)
            .set('total', payload.data.total);


    case GET_REQUESTS_LIST_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
