import React from 'react';
import { Input, Select } from 'antd';

import Icon from '../../elements/Icon';
import Layout from '../../components/layout/Layout';
import WhsLogo from '../../assets/images/wsh-logo.svg';

import './style.scss';

export default function () {
    const { faqCategories } = this.props;
    const backLink = {
        link: '/',
        title: 'Frequently Asked Questions',
    };

    return (
        <Layout title={<div>Welcome</div>} showMenu backLink={backLink} headerTitle={<div><img src={WhsLogo} alt="logo" /></div>}>
            <div className="content-wrap">
                <div className="searchbar">
                    <Select
                        className="category-select"
                        placeholder="All Categories"
                        size="large"
                        value={this.state.selectedCategory}
                        onChange={this.handleCategoryInput}
                        suffixIcon={<Icon name="chevron-down" />}>
                        <Select.Option key={null} value={null}>All categories</Select.Option>
                        {faqCategories ? faqCategories.map((c) => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)) : ''}
                    </Select>
                    <Input className="search-input" placeholder="Search" value={this.state.searchTerm} onChange={this.handleSearchInput} />
                    <button onClick={this.handleResetSearch} type="button" className="btn transparent" style={{ pointerEvents: 'all' }}>
                        <Icon name="close" />
                    </button>
                </div>
                <div className="faq-body">
                    {this.filteredFaqList.map(faq => (
                        <div className="faq-item" key={faq.id}>
                            <div className="faq-question">
                                <h4 className="h-4">{faq.question}</h4>
                            </div>
                            <div className="faq-answer">
                                <span>{faq.answer}</span>
                            </div>
                            { (faq.faq_category) ? (
                                <div className="faq-category">
                                    <span>Category: {faq.faq_category.name}</span>
                                </div>
                            ) : null}
                            <br />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
}
