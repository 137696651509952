import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_GUIDE_REQUEST: null,
    GET_GUIDE_SUCCESS: null,
    GET_GUIDE_FAILURE: null,
});

export const {
    getGuideRequest,
    getGuideSuccess,
    getGuideFailure,
} = createActions(
    constants.GET_GUIDE_REQUEST,
    constants.GET_GUIDE_SUCCESS,
    constants.GET_GUIDE_FAILURE,
);
