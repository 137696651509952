import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { message } from 'antd';
import global from './global/globalReducer';
import user from './user/userReducer';
import report from './report/reportReducer';
import chat from './chat/chatReducer';
import category from './category/categoryReducer';
import search from './search/searchReducer';
import supplier from './supplier/supplierReducer';
import help from './help/helpReducer';
import company from './company/companyReducer';
import requests from './requests/requestsReducer';
import faq from './faq/faqReducer';
import setting from './setting/settingReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    report,
    help,
    chat,
    category,
    search,
    form: formReducer,
    supplier,
    company,
    requests,
    faq,
    setting,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        const errorMessage = action.payload && action.payload.message
            ? action.payload.message
            : action.payload;

        if (typeof action.payload === 'string') {
            message.error(action.payload);
        }

        if (errorMessage === 'Unauthenticated.') {
            window.location.href = '/vine-logout';
        }
    }

    return appReducers(state, action);
};

export default rootReducer;
