import { Record } from 'immutable';

const {
    GET_SUGGESTIONS_REQUEST,
    GET_SUGGESTIONS_SUCCESS,
    GET_SUGGESTIONS_FAILURE,

    GET_RESULTS_REQUEST,
    GET_RESULTS_SUCCESS,
    GET_RESULTS_FAILURE,

    GET_SUPPLIER_REQUEST,
    GET_SUPPLIER_SUCCESS,
    GET_SUPPLIER_FAILURE,

    GET_SUPPLIER_PARENT_REQUEST,
    GET_SUPPLIER_PARENT_SUCCESS,
    GET_SUPPLIER_PARENT_FAILURE,

    GET_PINS_REQUEST,
    GET_PINS_SUCCESS,
    GET_PINS_FAILURE,

    CLEAR_SEARCH,
    SHOW_RESULTS_SIDEBAR,
    HIDE_RESULTS_SIDEBAR,
    SHOW_SUPPLIER_DETAILS,
    HIDE_SUPPLIER_DETAILS,
    CLEAR_SUPPLIER_DETAILS,

    CLEAR_SEARCH_REDUCER,

} = require('./searchActions').constants;

const InitialState = Record({
    suggestions: {},
    resultsSidebar: false,
    showSupplierDetails: false,
    results: [],
    pins: [],
    groupBy: null,
    searchParams: {},
    resultCount: 10,
    supplier: {},
    supplierParent: {},
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

export default function globalReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.merge(state);

    switch (type) {

    case GET_SUGGESTIONS_REQUEST:
    case GET_SUPPLIER_REQUEST:
    case GET_SUPPLIER_PARENT_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_PINS_REQUEST:
        return state.set('isFetching', true).set('groupBy', payload.groupBy || null).set('error', null).set('pins', []);

    case GET_RESULTS_REQUEST:
        return state.set('isFetching', true).set('error', null).set('searchParams', payload);

    case GET_SUGGESTIONS_SUCCESS:
        return state.set('suggestions', payload).set('isFetching', false);

    case GET_RESULTS_SUCCESS: {
        const results = parseInt(payload.start, 10) ? state.results : [];
        return state.set('results', [...results, ...payload.results]).set('isFetching', false).set('resultCount', payload.resultCount);
    }

    case GET_SUPPLIER_SUCCESS:
        return state.set('supplier', payload).set('isFetching', false);

    case GET_SUPPLIER_PARENT_SUCCESS:
        return state.set('supplierParent', payload).set('isFetching', false);

    case GET_PINS_SUCCESS:
        return state.set('pins', payload).set('isFetching', false);

    case GET_SUGGESTIONS_FAILURE:
    case GET_RESULTS_FAILURE:
    case GET_SUPPLIER_FAILURE:
    case GET_SUPPLIER_PARENT_FAILURE:
    case GET_PINS_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    case SHOW_RESULTS_SIDEBAR:
        return state.set('resultsSidebar', true);

    case HIDE_RESULTS_SIDEBAR:
        return state.set('resultsSidebar', false);

    case SHOW_SUPPLIER_DETAILS:
        return state.set('showSupplierDetails', true);

    case HIDE_SUPPLIER_DETAILS:
        return state.set('showSupplierDetails', false);

    case CLEAR_SEARCH:
        return state.set('results', []).set('resultCount', 0).set('searchParams', {});

    case CLEAR_SUPPLIER_DETAILS:
        return state.set('supplier', {}).set('supplierParent', {});

    case CLEAR_SEARCH_REDUCER:
        return state.set('suggestions', {})
            .set('resultsSidebar', false)
            .set('showSupplierDetails', false)
            .set('results', [])
            .set('pins', [])
            .set('searchParams', {})
            .set('resultCount', 10)
            .set('supplier', {})
            .set('supplierParent', {})
            .set('isFetching', false)
            .set('error', null);

    default:
        return state;
    }
}
