import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    GET_USER_DATA_REQUEST: null,
    GET_USER_DATA_SUCCESS: null,
    GET_USER_DATA_FAILURE: null,

    UPDATE_ACTIVE_COMPANY_REQUEST: null,
    UPDATE_ACTIVE_COMPANY_SUCCESS: null,
    UPDATE_ACTIVE_COMPANY_FAILURE: null,

    UPDATE_LOCATION_REQUEST: null,
    UPDATE_LOCATION_SUCCESS: null,
    UPDATE_LOCATION_FAILURE: null,

    SET_LOCATION: null,
    CLEAR_USER_DATA: null,

});

export const {
    deleteTokenRequest,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    getUserDataRequest,
    getUserDataSuccess,
    getUserDataFailure,
} = createActions(
    constants.GET_USER_DATA_REQUEST,
    constants.GET_USER_DATA_SUCCESS,
    constants.GET_USER_DATA_FAILURE,
);

export const {
    updateActiveCompanyRequest,
    updateActiveCompanySuccess,
    updateActiveCompanyFailure,
} = createActions(
    constants.UPDATE_ACTIVE_COMPANY_REQUEST,
    constants.UPDATE_ACTIVE_COMPANY_SUCCESS,
    constants.UPDATE_ACTIVE_COMPANY_FAILURE,
);

export const {
    updateLocationRequest,
    updateLocationSuccess,
    updateLocationFailure,
} = createActions(
    constants.UPDATE_LOCATION_REQUEST,
    constants.UPDATE_LOCATION_SUCCESS,
    constants.UPDATE_LOCATION_FAILURE,
);

export const {
    setLocation,
    clearUserData,
} = createActions(
    constants.SET_LOCATION,
    constants.CLEAR_USER_DATA,
);
