import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import QueryString from 'qs';
import { message } from 'antd';
import { isEqual } from 'underscore';

import * as supplierActions from '../../core/supplier/supplierActions';

import DOM from './useExistingSupplier';

class UseExistingSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.existingSupplierForm = React.createRef();
        this.state = {
            supplier: null,
            selectedCategory: null,
            selectedSubCategory: null,
            supplyStarted: 0,
        };
    }

    componentDidMount() {
        const { actions, location, categories } = this.props;
        const supplier = this.findSupplier(location.search.replace(/^\?/, ''));
        const state = { supplier };

        if (categories.length) {
            const selectedCategory = categories[0];
            state.selectedCategory = selectedCategory;
            state.selectedSubCategory = selectedCategory.sub_categories.length ? selectedCategory.sub_categories[0] : selectedCategory;
        }

        this.setState(state);

        actions.getSuppliersListRequest({ id: supplier });
        actions.getTopSupplierCategoriesRequest({ supplier_id: supplier });

        this.existingSupplierForm.current.setFieldsValue(this.props.user);
    }

    componentDidUpdate(prevProps) {
        const { categories } = this.props;
        const { categories: prevCategories } = prevProps;

        if (!isEqual(prevCategories, categories) && categories.length) {
            const selectedCategory = categories[0];
            this.setState({
                selectedCategory,
                selectedSubCategory: selectedCategory.sub_categories.length ? selectedCategory.sub_categories[0] : selectedCategory,
            });
        }
    }

    categoryChange = (id) => {
        const { categories } = this.props;
        const selectedCategory = categories.find(c => c.id === id);
        if (selectedCategory) {
            this.setState({
                selectedCategory,
                selectedSubCategory: selectedCategory.sub_categories.length ? selectedCategory.sub_categories[0] : selectedCategory,
            });
        }
    }

    subCategoryChange = (id) => {
        const { categories } = this.props;
        const selectedCategory = categories.find(c => c.id === id);
        if (selectedCategory) {
            const selectedSubCategory = selectedCategory.sub_categories.find(c => c.id === id);
            this.setState({ selectedSubCategory });
        }
    }

    clearCategories = () => {
        this.setState({ selectedCategory: undefined, selectedSubCategory: undefined });
    }

    findSupplier = (searchString) => {
        try {
            if (!searchString.includes('supplier')) {
                return null;
            }
            const params = QueryString.parse(searchString);
            const supplierId = params.supplier;
            return supplierId;
        } catch (e) {
            return null;
        }

    }

    sendRequest = (vals) => {
        const { selectedSubCategory, supplier } = this.state;
        const { actions } = this.props;

        if (!selectedSubCategory) {
            message.error('Please select a primary category');
            return;
        }

        const data = vals;
        if (supplier) {
            data.supplier_id = supplier;
        }
        data.category_id = selectedSubCategory.id;
        data.start_date = vals.start_date.format('YYYY-MM-DD HH:mm:ss');
        if (vals.end_date) {
            data.end_date = vals.end_date.format('YYYY-MM-DD HH:mm:ss');
        }
        actions.sendUseSupplierFormRequest(data);
    }

    render() {
        return this.view();
    }
}

UseExistingSupplier.propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    suppliers: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    subcategories: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        suppliers: state.supplier.suppliersList,
        categories: state.supplier.topCategories,
        subcategories: state.supplier.subCategories,
        user: state.user.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...supplierActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UseExistingSupplier);
