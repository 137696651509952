import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const { BUGSNAG_KEY, DEBUG } = require('../constants').default;

const debug = {};

const getWrapper = () => {
    if (DEBUG || !BUGSNAG_KEY) {
        return ({ children }) => children;
    }

    const bugsnagClient = bugsnag(BUGSNAG_KEY);
    bugsnagClient.use(bugsnagReact, React);

    return bugsnagClient.getPlugin('react');
};

debug.getWrapper = getWrapper;

export default debug;
