import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_SUGGESTIONS_REQUEST: null,
    GET_SUGGESTIONS_SUCCESS: null,
    GET_SUGGESTIONS_FAILURE: null,

    GET_RESULTS_REQUEST: null,
    GET_RESULTS_SUCCESS: null,
    GET_RESULTS_FAILURE: null,

    GET_SUPPLIER_REQUEST: null,
    GET_SUPPLIER_SUCCESS: null,
    GET_SUPPLIER_FAILURE: null,

    GET_SUPPLIER_PARENT_REQUEST: null,
    GET_SUPPLIER_PARENT_SUCCESS: null,
    GET_SUPPLIER_PARENT_FAILURE: null,

    GET_PINS_REQUEST: null,
    GET_PINS_SUCCESS: null,
    GET_PINS_FAILURE: null,

    CLEAR_SEARCH: null,
    SHOW_RESULTS_SIDEBAR: null,
    HIDE_RESULTS_SIDEBAR: null,
    SHOW_SUPPLIER_DETAILS: null,
    HIDE_SUPPLIER_DETAILS: null,
    CLEAR_SUPPLIER_DETAILS: null,

    CLEAR_SEARCH_REDUCER: null,
});

export const {
    getSuggestionsRequest,
    getSuggestionsSuccess,
    getSuggestionsFailure,

    getResultsRequest,
    getResultsSuccess,
    getResultsFailure,

    getSupplierRequest,
    getSupplierSuccess,
    getSupplierFailure,

    getSupplierParentRequest,
    getSupplierParentSuccess,
    getSupplierParentFailure,

    getPinsRequest,
    getPinsSuccess,
    getPinsFailure,

} = createActions(
    constants.GET_SUGGESTIONS_REQUEST,
    constants.GET_SUGGESTIONS_SUCCESS,
    constants.GET_SUGGESTIONS_FAILURE,

    constants.GET_RESULTS_REQUEST,
    constants.GET_RESULTS_SUCCESS,
    constants.GET_RESULTS_FAILURE,

    constants.GET_SUPPLIER_PARENT_REQUEST,
    constants.GET_SUPPLIER_PARENT_SUCCESS,
    constants.GET_SUPPLIER_PARENT_FAILURE,

    constants.GET_SUPPLIER_REQUEST,
    constants.GET_SUPPLIER_SUCCESS,
    constants.GET_SUPPLIER_FAILURE,

    constants.GET_PINS_REQUEST,
    constants.GET_PINS_SUCCESS,
    constants.GET_PINS_FAILURE,
);

export const {
    clearSearch,
    showResultsSidebar,
    hideResultsSidebar,
    showSupplierDetails,
    hideSupplierDetails,
    clearSupplierDetails,
} = createActions(
    constants.CLEAR_SEARCH,
    constants.SHOW_RESULTS_SIDEBAR,
    constants.HIDE_RESULTS_SIDEBAR,
    constants.SHOW_SUPPLIER_DETAILS,
    constants.HIDE_SUPPLIER_DETAILS,
    constants.CLEAR_SUPPLIER_DETAILS,
);

export const {
    clearSearchReducer,
} = createActions(
    constants.CLEAR_SEARCH_REDUCER,
);
