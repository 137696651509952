import { Record } from 'immutable';

const {
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILURE,
} = require('./categoryActions').constants;

const InitialState = Record({
    categories: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

export default function globalReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_CATEGORIES_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_CATEGORIES_SUCCESS: {
        return state.set('categories', payload)
            .set('isFetching', false);
    }

    case GET_CATEGORIES_FAILURE:
        return state.set('error', payload).set('isFetching', false);

    default:
        return state;

    }
}
