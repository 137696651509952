import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    sendHelpRequestFormSuccess,
    sendHelpRequestFormFailure,

} from './helpActions';

const {
    SEND_HELP_REQUEST_FORM_REQUEST,
} = require('./helpActions').constants;

function* sendHelpRequestForm(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'help-request/update',
            body: actions.payload,
        });
        message.success('Successfully submitted help request');
        yield put(sendHelpRequestFormSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(sendHelpRequestFormFailure(e.message ? e.message : e));
    }
}

/**
 * Watch actions
 */
export default function* helpSaga() {
    yield all([
        takeEvery(SEND_HELP_REQUEST_FORM_REQUEST, sendHelpRequestForm),
    ]);
}
