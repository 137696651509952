import React from 'react';
import { Row, Col, Form, Select, Button, Input, Radio, DatePicker, Upload } from 'antd';
import Layout from '../../components/layout/Layout';
import Icon from '../../elements/Icon';
import { getApiUrl, getAuthHeaders } from '../../core/utils/api';
import WhsLogo from '../../assets/images/wsh-logo.svg';

import './style.scss';
import CompanySelector from '../../components/CompanySelector';

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;

export default function () {
    const menu = [
        { label: 'Find a Supplier', link: '/', className: 'is-active' },
        { label: 'Report Food Scare (FS14)', link: '/report-food-scare' },
        { label: 'Your Requests', link: '/your-requests' },
        { label: 'FAQs' },
        { label: 'Supply Chain Tools', link: '/search-results' },
        { label: 'Admin', link: '/', style: { paddingLeft: '72px' } },
    ];
    const backLink = {
        link: '/',
        title: 'Report Food Scare',
    };
    // eslint-disable-next-line no-unused-vars
    const infoList = [
        {
            id: '001',
            title: 'Location name',
            descr: 'Big Johns Factory',
        },
        {
            id: '002',
            title: 'Sub account number:',
            descr: '2343243233',
        },
        {
            id: '003',
            title: 'Building name:',
            descr: 'Main Hall',
        },
        {
            id: '004',
            title: 'Address 1:',
            descr: '123 French Street',
        },
        {
            id: '005',
            title: 'Address 2:',
            descr: '',
        },
        {
            id: '006',
            title: 'Town:',
            descr: 'Ridgley',
        },
        {
            id: '007',
            title: 'Country:',
            descr: 'West Midlands',
        },
        {
            id: '008',
            title: 'Postcode:',
            descr: 'WM 12 EM',
        },
        {
            id: '009',
            title: 'Country:',
            descr: 'UK',
        },
        {
            id: '010',
            title: 'Location telephone:',
            descr: '0212 1231231321',
        },
        {
            id: '011',
            title: 'Location email:',
            descr: 'jsnesmith@baxterstorey.com',
        },
        {
            id: '012',
            title: 'Delivery instructions:',
            descr: 'Please leave at the rear entrance',
        },
    ];

    const { relatesToOptions, complaintTypeOptions } = this.props;
    const { fileList } = this.state;

    return (
        <Layout title={<div>Welcome</div>} menu={menu} backLink={backLink} headerTitle={<div><img src={WhsLogo} alt="logo" /></div>}>
            <div className="content-wrap">
                <div className="content-contr">
                    <Form layout="vertical" name="food-scare-form" onFinish={this.submitForm}>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Complaint Details</h4>
                            </div>
                            <div className="mt-3">
                                <Form.Item
                                    label="This complaint relates to"
                                    name="relates_to"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Select loading={relatesToOptions === null} size="large">
                                        {relatesToOptions
                                            ? relatesToOptions.map(item => (
                                                <Option key={item.key} value={item.key}>
                                                    {item.label}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Type of complaint"
                                    name="complaint_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Select loading={complaintTypeOptions === null} size="large">
                                        {complaintTypeOptions
                                            ? complaintTypeOptions.map(item => (
                                                <Option key={item.key} value={item.key}>
                                                    {item.label}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Date and Time of complaint received"
                                    name="date_received"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        showTime={{ format: 'HH:mm' }}
                                        format="HH:mm DD/MM/YYYY"
                                        size="large"
                                        suffixIcon={<Icon name="calendar" />} />
                                </Form.Item>
                                <Form.Item
                                    label="Name of person who received the complaint"
                                    name="recipient"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item label="Description of the complaint" name="description">
                                    <TextArea size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Was injury/sickness caused?"
                                    name="caused_injury"
                                    value={this.state.caused_injury}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Radio.Group
                                        onChange={(val) => this.setState({ caused_injury: val.target.value })}
                                        value={this.state.caused_injury}>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {
                                    this.state.caused_injury === 1
                                        ? (
                                            <Form.Item
                                                label="Details of any injury/sickness caused"
                                                name="injury_details"
                                                rules={[]}>
                                                <TextArea size="large" />
                                            </Form.Item>
                                        )
                                        : null
                                }

                                <Form.Item
                                    label="Did you receive the foreign body or food product from the person complaining?"
                                    name="product_source"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value="Unknown">No</Radio>
                                        <Radio value="Complainant">Yes</Radio>
                                        <Radio value="Not applicable">Not applicable</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Row type="flex" gutter={40}>
                                    <Col span={12} xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Number of people affected"
                                            name="people_affected"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" type="number" min="0" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Number of portions sold"
                                            name="portions_sold"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" type="number" min="0" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Complainant Details</h4>
                                <p className="block__header-subtitle">
                                    Please provide the details of the person who made the complaint
                                </p>
                            </div>
                            <div>
                                <Form.Item
                                    label="Has the complainant consented for us to record their personal information against this complaint?"
                                    name="complainant_consent"
                                    value={this.state.complainant_consent}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Radio.Group
                                        onChange={(val) => this.setState({ complainant_consent: val.target.value })}
                                        value={this.state.complainant_consent}>
                                        <Radio value={0}>No</Radio>
                                        <Radio value={1}>Yes</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {this.state.complainant_consent ? (
                                    <>
                                        <Form.Item
                                            label="Name"
                                            name="complainant_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>
                                        <Form.Item label="Postal address" name="complainant_address">
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item
                                            label="What advice did you or the location give to them?"
                                            name="advice_given">
                                            <TextArea />
                                        </Form.Item>
                                    </>
                                ) : null}

                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Report submitted by</h4>
                                <p className="block__header-subtitle">Please confirm your details</p>
                            </div>
                            <div>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Contact number"
                                    name="phone_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item label="Job title" name="job_title">
                                    <Input size="large" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Your Company and Location</h4>
                            </div>
                            <Form.Item rules={[{ required: true, message: 'Please select a branch' }]} name="company_id">
                                <CompanySelector />
                            </Form.Item>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Attachments</h4>
                                <p className="block__header-subtitle" style={{ maxWidth: 486, margin: '8px auto 0px' }}>
                                    Optionally please add any relevant attachments. Supported formats: zip, jpeg, png,
                                    txt, pdf, docx, xls. Max file size 5MB
                                </p>
                            </div>
                            <Dragger
                                className="dragger"
                                accept=".zip,.jpg,.png,.txt,.pdf,.docx,.xlsx"
                                action={`${getApiUrl() && getApiUrl().replace(/\/+$/, '')}/dropzone/update`}
                                headers={getAuthHeaders()}
                                name="files[]"
                                fileList={fileList}
                                beforeUpload={this.beforeUpload}
                                onChange={this.onUploadChange}
                                onRemove={this.onUploadRemove}
                                showUploadList={{ showDownloadIcon: true }}
                                onDownload={
                                    (file) => {
                                        this.downloadFile(file);
                                    }
                                }>
                                <div className="dragger__inner">
                                    <h4 className="h-4">Drag and Drop to Upload</h4>
                                    <span className="mt-1">or</span>
                                    <Button className="secondary small mt-1">
                                        <span className="c_blue">Browse</span>
                                    </Button>
                                </div>
                            </Dragger>
                        </div>
                        <div className="mt-5 text-center">
                            <Button type="primary" htmlType="submit" className="primary wide">
                                Send
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
}
