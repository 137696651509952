import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu } from 'antd';
import { isEmpty, map } from 'underscore';
import { Link } from 'react-router-dom';

import Icon from '../../../elements/Icon';

const { Item } = Menu;

const CompanySelect = ({ mobile, avatar, company, companies, onCompanyChange }) => {

    const menu = (
        <Menu>
            {map(companies, c => (
                <Item key={c.id} onClick={() => onCompanyChange(c.id)}>{c.name}</Item>
            ))}
        </Menu>
    );

    return (
        <div className={mobile ? 'drawer__header' : 'menu__footer'}>
            <div className="profile">
                <div className="profile__avatar">
                    <img src={avatar} alt="Avatar" />
                </div>
                <div className="profile__info">
                    <div className="info__title">
                        <span>{company.name} #{company.number}</span>

                        {!isEmpty(companies) ? (
                            <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
                                <Button className="btn transparent">
                                    <Icon name="chevron-down" />
                                </Button>
                            </Dropdown>
                        ) : false}
                    </div>
                    {company.parent ? <div className="info__company">{company.parent.name}</div> : false}
                    {!mobile ? <Link to="/logout" className="underlined">Sign Out</Link> : false}
                </div>
            </div>
        </div>
    );
};

CompanySelect.defaultProps = {
    avatar: null,
    company: {},
    companies: [],
    onCompanyChange: () => {},
    mobile: false,
};

CompanySelect.propTypes = {
    avatar: PropTypes.string,
    company: PropTypes.object,
    companies: PropTypes.array,
    onCompanyChange: PropTypes.func,
    mobile: PropTypes.bool,
};

export default CompanySelect;
