import React from 'react';
import { Layout } from 'antd';
import './style.scss';

export default function () {
    const {
        children, floating,
    } = this.props;
    return (
        <Layout.Footer className={`footer ${floating ? 'floating-footer' : ''}`}>
            {children}
        </Layout.Footer>
    );
}
