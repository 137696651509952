import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import {
    getFaqDataSuccess,
    getFaqDataFailure,
    getFaqCategoriesSuccess,
    getFaqCategoriesFailure,
} from './faqActions';

const {
    GET_FAQ_DATA_REQUEST,
    GET_FAQ_CATEGORIES_REQUEST,
} = require('./faqActions').constants;

function* getFaqData() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'faq/list',
        });

        yield put(getFaqDataSuccess(response.data));
    } catch (e) {
        yield put(getFaqDataFailure(e.message ? e.message : e));
    }
}

function* getFaqCategories() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'faq/faqCategories',
        });

        yield put(getFaqCategoriesSuccess(response.data));
    } catch (e) {
        yield put(getFaqCategoriesFailure(e.message ? e.message : e));
    }
}

/**
 * Watch actions
 */
export default function* faqSaga() {
    yield all([
        takeEvery(GET_FAQ_DATA_REQUEST, getFaqData),
        takeEvery(GET_FAQ_CATEGORIES_REQUEST, getFaqCategories),
    ]);
}
