import React from 'react';
import { Row, Col } from 'antd';
import SidebarResults from '../SidebarResults';
import './style.scss';

export default function () {
    return (
        <Row type="flex" style={{ height: '100%' }}>
            <Col style={{ paddingLeft: '0', paddingRight: '0px' }}>
                <SidebarResults getSupplierDetails={this.props.getSupplierDetails} selectSupplier={this.props.selectSupplier} />
            </Col>
        </Row>
    );
}
