import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as requestsActions from '../../core/requests/requestsActions';
import * as globalActions from '../../core/global/globalActions';
import * as helpActions from '../../core/help/helpActions';

import DOM from './yourRequests';

class YourRequests extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            activeTabKey: 'all',
        };
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.getRequestsListRequest();
    }

    componentDidUpdate(prevProps) {
        const { currentCompany: oldCurrentCompany } = prevProps;
        const { currentCompany, actions } = this.props;
        if (oldCurrentCompany !== currentCompany) {
            actions.getRequestsListRequest();
        }
    }

    onTabsChange = (status) => {
        const { actions } = this.props;
        // Make new request with sort
        this.setState({ activeTabKey: status });
        if (status === 'all') {
            actions.getRequestsListRequest();
        } else {
            actions.getRequestsListRequest({ status });
        }
    };

    onPageChange = (page) => {
        const { actions } = this.props;
        const { activeTabKey: status } = this.state;
        if (status === 'all') {
            actions.getRequestsListRequest({ page });
        } else {
            actions.getRequestsListRequest({ page, status });
        }
    }

    render() {
        return this.view();
    }
}

YourRequests.propTypes = {
    actions: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    requests: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    currentCompany: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        page: state.requests.page,
        requests: state.requests.items,
        total: state.requests.total,
        loading: state.requests.isFetching,
        user: state.user,
        currentCompany: state.user.userData && state.user.userData.active_company_id,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...requestsActions,
                ...globalActions,
                ...helpActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(YourRequests);
