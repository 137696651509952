import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getRequestsListSuccess,
    getRequestsListFailure,

} from './requestsActions';

const {
    GET_REQUESTS_LIST_REQUEST,
} = require('./requestsActions').constants;

function* getRequestsList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'trading-relationship/list',
            params: action.payload,
        });

        yield put(getRequestsListSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getRequestsListFailure(e.message ? e.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(GET_REQUESTS_LIST_REQUEST, getRequestsList),
    ]);
}
