import React from 'react';
import GooglePlacesSuggest from 'react-google-places-suggest';
import { isObject, isEmpty } from 'underscore';

import { Input, Modal, Button, Col, Row } from 'antd';
import Icon from '../../elements/Icon';
import './style.scss';

export default function () {
    const { searchInput, defaultLocation } = this.state;
    const { maps, availableLocations, map, locationModalVisible, closeLocationModal, currentCompany } = this.props;

    let filteredLocations = availableLocations;
    if (searchInput) {
        filteredLocations = availableLocations.filter(l => (
            l.name.toLowerCase().includes(searchInput.toLowerCase())
            || l.number.toString().toLowerCase().includes(searchInput.toLowerCase())
            || l.location.address.toLowerCase().includes(searchInput.toLowerCase())
        )).slice(0, 5);
    }

    return (
        <Modal
            visible={locationModalVisible}
            width={380}
            footer={null}
            closable={false}
            onCancel={closeLocationModal}
            className="modal-custom">
            <button type="button" className="close-btn btn transparent" onClick={closeLocationModal}>
                <Icon name="close" />
            </button>
            <h4 className="h-4 text-center">Your location</h4>
            {currentCompany && currentCompany !== {} ? (
                <div className="current-location">
                    <p className="cl-name"><b>{currentCompany.name}</b></p>
                    <p>{currentCompany.location && currentCompany.location.address} </p>
                </div>
            ) : '' }

            <div className="text-center mt-2">
                {maps ? (
                    <GooglePlacesSuggest
                        googleMaps={maps}
                        autocompletionRequest={{
                            input: isObject(searchInput) ? searchInput.address : searchInput,
                        }}
                        onSelectSuggest={this.handleSelectSuggest}
                        textNoResults="Could not find location"
                        customRender={prediction => (
                            <div className="location-list-item">
                                {prediction ? prediction.description : 'Could not find location'}
                            </div>
                        )}
                        customContainerRender={!isEmpty(filteredLocations) ? () => false : undefined}>
                        <Input
                            className="location-input-box"
                            value={searchInput}
                            prefix={<Icon name="location" />}
                            placeholder="Search for a location"
                            onChange={this.handleInputChange}
                            size="large"
                            allowClear />
                    </GooglePlacesSuggest>
                ) : null}
            </div>

            {!isEmpty(filteredLocations) ? (
                <div className="location-list">
                    {filteredLocations.map(l => (
                        <div key={l.id} className="location-list-item" onClick={() => this.updateLocation(l.id)}>{l.name}</div>
                    ))}
                </div>
            ) : false}

            {defaultLocation !== null ? (
                <div className="text-center mt-1">
                    {map ? <Button onClick={this.resetLocation} className="btn underlined">Reset to your WSH</Button> : ''}
                </div>
            ) : null}

            <Row type="flex" align="middle" gutter={12} className="mt-4">
                <Col span={12}>
                    <Button onClick={this.saveLocation} className="btn primary w100">Update</Button>
                </Col>
                <Col span={12}>
                    <Button className="btn secondary w100" onClick={closeLocationModal}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    );
}
