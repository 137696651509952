import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './requestItem';

class RequestItem extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

RequestItem.defaultProps = {
    type: 'Pending',
    accountNumber: 'Not Specified',
    priceLevel: 'Not Specified',
    startDate: 'Not Specified',
    endDate: 'Not Specified',
    supplierName: 'Example Supplier',
    supplierWebsite: 'http://example.com',
    supplyStartDate: '1970-01-01',
};

RequestItem.propTypes = {
    type: PropTypes.string,
    accountNumber: PropTypes.string,
    priceLevel: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    supplierName: PropTypes.string,
    supplierWebsite: PropTypes.string,
    supplyStartDate: PropTypes.string,
};

export default RequestItem;
