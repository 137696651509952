/* global sessionStorage: true */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual, filter, find } from 'underscore';
import moment from 'moment';

import DOM from './locationPicker';

import * as userActions from '../../core/user/userActions';

class LocationPicker extends React.Component {

    constructor(props) {
        super(props);
        this.view = DOM;
        this.locationInput = React.createRef();
        this.state = {
            visible: false,
            defaultLocation: '',
            searchInput: '',
            value: false,
        };
    }

    componentDidMount() {
        const { currentLocation, defaultLocation } = this.props;

        if (currentLocation) {
            this.setState({
                value: currentLocation,
                searchInput: '',
            });
        } else if (defaultLocation) {
            this.setState({
                value: defaultLocation.address,
                searchInput: '',
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { actions, map, defaultLocation } = this.props;
        const { defaultLocation: prevDefaultLocation } = prevProps;

        if (defaultLocation && !isEqual(defaultLocation, prevDefaultLocation)) {
            const { coordinates } = defaultLocation;
            map.setCenter(coordinates);
            this.setState({ value: defaultLocation, searchInput: '' });
            actions.setLocation(defaultLocation);
        }
    }

    handleInputChange = e => {
        const { value } = e.target;
        this.setState({ searchInput: value });
    }

    handleSelectSuggest = (geocode) => {
        const { formatted_address, geometry } = geocode;

        this.setState(
            {
                searchInput: formatted_address,
                value: {
                    address: formatted_address,
                    coordinates:
                        {
                            lat: geometry.location.lat(),
                            lng: geometry.location.lng(),
                        },
                },
            },
        );
    }

    updateLocation = (id) => {
        const { actions, map, availableLocations, closeLocationModal, updatePins } = this.props;
        const location = find(availableLocations, l => l.id === id);

        actions.setLocation(location.location);
        actions.updateLocationRequest({ location_id: id, last_used: moment().format('YYYY-MM-DD HH:mm:ss') });
        actions.updateActiveCompanyRequest({ company_id: id });

        sessionStorage.setItem('sessionInUse', true);
        closeLocationModal();
        updatePins();
        map.setCenter(location.location.coordinates);
    }

    saveLocation = () => {
        const { actions, closeLocationModal, map } = this.props;
        const { value } = this.state;

        actions.setLocation(value);
        sessionStorage.setItem('sessionInUse', true);
        closeLocationModal();
        map.setCenter(value.coordinates);
    }

    resetLocation = () => {
        const { actions, defaultLocation, map, closeLocationModal } = this.props;
        actions.setLocation(defaultLocation);
        this.setState({
            value: defaultLocation,
            searchInput: '',
        });

        map.setCenter(defaultLocation.coordinates);
        sessionStorage.setItem('sessionInUse', true);
        closeLocationModal();
    }

    render() {
        return this.view();
    }
}

LocationPicker.defaultProps = {
    defaultLocation: null,
    currentLocation: null,
    map: null,
    supplier: null,
    availableLocations: [],
};

LocationPicker.propTypes = {
    actions: PropTypes.object.isRequired,
    defaultLocation: PropTypes.object,
    currentLocation: PropTypes.object,
    supplier: PropTypes.object,
    closeLocationModal: PropTypes.func.isRequired,
    map: PropTypes.object,
    availableLocations: PropTypes.array,
    updatePins: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    const { user } = state;
    let availableLocations = [];
    let currentCompany = {};
    if (user.userData && user.userData.available_companies) {
        currentCompany = find(user.userData.available_companies, c => c.id === user.userData.active_company_id);
        if (currentCompany) {
            availableLocations = filter(user.userData.available_companies, c => (
                c.parent_id
                && c.parent_id === currentCompany.parent_id
                && c.id !== currentCompany.id
            ));
        }
    }

    return {
        defaultLocation: user.userData.location,
        currentLocation: user.mapLocation,
        currentCompany,
        availableLocations,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationPicker);
