import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_CATEGORIES_REQUEST: null,
    GET_CATEGORIES_SUCCESS: null,
    GET_CATEGORIES_FAILURE: null,
});

export const {
    getCategoriesRequest,
    getCategoriesSuccess,
    getCategoriesFailure,
} = createActions(
    constants.GET_CATEGORIES_REQUEST,
    constants.GET_CATEGORIES_SUCCESS,
    constants.GET_CATEGORIES_FAILURE,
);
