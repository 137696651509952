/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_PARENT_COMPANIES_LIST_REQUEST,
    GET_PARENT_COMPANIES_LIST_SUCCESS,
    GET_PARENT_COMPANIES_LIST_FAILURE,

    GET_CHILD_COMPANY_LIST_REQUEST,
    GET_CHILD_COMPANY_LIST_SUCCESS,
    GET_CHILD_COMPANY_LIST_FAILURE,

    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILURE,

    SELECT_SUB_COMPANY,

} = require('./companyActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    topCompanies: [],
    subCompanies: [],
    selectedTopCompany: null,
    selectedSubCompany: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {
    case GET_PARENT_COMPANIES_LIST_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_CHILD_COMPANY_LIST_REQUEST:
        return state.set('isFetching', true)
            .set('selectedTopCompany', payload.company_id)
            .set('selectedSubCompany', null)
            .set('error', null);
    case UPDATE_COMPANY_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_PARENT_COMPANIES_LIST_SUCCESS:
        return state.set('topCompanies', payload.data)
            .set('selectedTopCompany', null)
            .set('selectedSubCompany', null)
            .set('isFetching', false);
    case GET_CHILD_COMPANY_LIST_SUCCESS:
        return state.set('subCompanies', payload.data).set('isFetching', false);
    case UPDATE_COMPANY_SUCCESS:
        return state.set('isFetching', false)
            .set('selectedSubCompany', payload.data.company_id)
            .set('subCompanies', payload.data.list);


    case GET_PARENT_COMPANIES_LIST_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case GET_CHILD_COMPANY_LIST_FAILURE:
        return state.set('isFetching', false)
            .set('selectedTopCompany', null)
            .set('error', payload);
    case UPDATE_COMPANY_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);


    case SELECT_SUB_COMPANY:
        return state.set('selectedSubCompany', payload.company_id);


    default:
        return state;
    }
}
