import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import { map, isNull, find, filter } from 'underscore';

import userAvatar from '../../../assets/images/default-avatar.png';
import LogoSrc from '../../../assets/images/scout-logo.png';
import Icon from '../../../elements/Icon';
import CompanySelect from '../CompanySelect';
import './style.scss';

const {
    API_URL,
} = require('../../../core/constants').default;

const adminLink = API_URL.substring(0, API_URL.length - 3);

export default function () {
    const {
        user, items, logo, placement, width,
    } = this.props;
    const { menuOpen } = this.state;

    let menu = items;
    if (menu.length === 0) {
        const rawUserRole = (user.userData && user.userData.role && user.userData.role.name) || 'unknown';
        const userRole = rawUserRole.toLowerCase();
        // If no menu is provided, create a default one
        menu = [
            { label: 'Find a Supplier', link: '/search' },
            { label: 'Report Food Scare (FS14)', link: '/report-food-scare' },
        ];
        if (userRole === 'user' || userRole === 'scorchsoft') {
            menu.push({ label: 'Your Requests', link: '/your-requests' });
        }
        menu.push({ label: 'FAQs', link: '/faq' });
        if (userRole === 'supply chain manager' || userRole === 'scorchsoft') {
            menu.push({ label: 'Supply Chain Tools', style: { paddingBottom: '0px' } });
        }
        if (userRole === 'admin' || userRole === 'supply chain manager' || userRole === 'scorchsoft') {
            menu.push({ label: 'Admin', href: adminLink, style: { marginLeft: '50px' } });
        }

        // Highlight the current page
        menu.map((menuItem) => {
            const alterableMenuItem = menuItem;
            delete alterableMenuItem.className;
            if (window.location.pathname === menuItem.link) {
                alterableMenuItem.className = 'is-active';
            }
            return alterableMenuItem;
        });
    }

    let companies = [];
    let company = {};
    if (user.userData && user.userData.available_companies) {
        company = find(user.userData.available_companies, c => c.id === user.userData.active_company_id);
        if (company) {
            if (company.parent_id) {
                const parent = find(user.userData.available_companies, c => c.id === company.parent_id);
                company.parent = parent;
            }

            companies = filter(user.userData.available_companies, c => (c.id !== company.id && !c.parent_id && c.id !== company.parent_id));
        }
    }

    return (
        <div className="menu">
            <Button className="menu-button transparent" onClick={this.openDrawer}>
                <Icon name="hamburger" />
            </Button>
            <Drawer
                visible={menuOpen}
                onClose={this.closeDrawer}
                closable={false}
                placement={placement}
                width={width}
                className="menu__drawer"
                maskClosable>
                <Button className="drawer__close-btn btn transparent" onClick={this.closeDrawer}>
                    <Icon name="close" />
                </Button>
                <div className="menu__header">
                    {!isNull(logo)
                        ? (
                            <Link to="/">
                                <img className="menu__logo" src={LogoSrc} alt="Logo" />
                            </Link>
                        )
                        : null}
                </div>
                <div className="menu__content">
                    <ul className="menu__nav">
                        {map(menu, (item, itemIndex) => (
                            <li key={item.key || `menu-item-${itemIndex}`}>
                                {item.href ? (
                                    <a href={item.href} className={`menu__navlink ${item.className || ''}`} style={item.style || null}>
                                        <span>{item.label}</span>
                                    </a>
                                ) : (
                                    <>
                                        {item.link ? (
                                            <Link to={item.link} className={`menu__navlink ${item.className || ''}`} style={item.style || null}>
                                                <span>{item.label}</span>
                                            </Link>
                                        ) : (
                                            <div className={`menu__navlink fake ${item.className || ''}`}>
                                                <span>{item.label}</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <CompanySelect
                    avatar={user && user.userData ? user.userData.profile_url : userAvatar}
                    company={company}
                    companies={companies}
                    onCompanyChange={this.updateCompany} />
            </Drawer>
        </div>
    );
}
