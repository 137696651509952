import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    CREATE_CHAT_REQUEST: null,
    CREATE_CHAT_SUCCESS: null,
    CREATE_CHAT_FAILURE: null,
});

export const {
    createChatRequest,
    createChatSuccess,
    createChatFailure,
} = createActions(
    constants.CREATE_CHAT_REQUEST,
    constants.CREATE_CHAT_SUCCESS,
    constants.CREATE_CHAT_FAILURE,
);
