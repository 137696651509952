/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_SUPPLIERS_LIST_REQUEST,
    GET_SUPPLIERS_LIST_SUCCESS,
    GET_SUPPLIERS_LIST_FAILURE,

    GET_TOP_SUPPLIER_CATEGORIES_REQUEST,
    GET_TOP_SUPPLIER_CATEGORIES_SUCCESS,
    GET_TOP_SUPPLIER_CATEGORIES_FAILURE,

    GET_SUB_SUPPLIER_CATEGORIES_REQUEST,
    GET_SUB_SUPPLIER_CATEGORIES_SUCCESS,
    GET_SUB_SUPPLIER_CATEGORIES_FAILURE,

    SEND_USE_SUPPLIER_FORM_REQUEST,
    SEND_USE_SUPPLIER_FORM_SUCCESS,
    SEND_USE_SUPPLIER_FORM_FAILURE,

    CLEAR_SUPPLIER_CATEGORIES,

} = require('./supplierActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    suppliersList: [],
    topCategories: [],
    subCategories: [],
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case GET_SUPPLIERS_LIST_REQUEST:
    case GET_TOP_SUPPLIER_CATEGORIES_REQUEST:
    case GET_SUB_SUPPLIER_CATEGORIES_REQUEST:
    case SEND_USE_SUPPLIER_FORM_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case GET_SUPPLIERS_LIST_SUCCESS:
        return state.set('suppliersList', payload.data).set('isFetching', false);
    case GET_TOP_SUPPLIER_CATEGORIES_SUCCESS:
        return state.set('topCategories', payload.data).set('isFetching', false);
    case GET_SUB_SUPPLIER_CATEGORIES_SUCCESS:
        return state.set('subCategories', payload.data).set('isFetching', false);
    case SEND_USE_SUPPLIER_FORM_SUCCESS:
        return state.set('isFetching', false);


    case GET_SUPPLIERS_LIST_FAILURE:
    case GET_TOP_SUPPLIER_CATEGORIES_FAILURE:
    case GET_SUB_SUPPLIER_CATEGORIES_FAILURE:
    case SEND_USE_SUPPLIER_FORM_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);


    case CLEAR_SUPPLIER_CATEGORIES:
        return state.set('topCategories', []).set('subCategories', []);


    default:
        return state;
    }
}
