import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { message } from 'antd';
import * as companyActions from '../../core/company/companyActions';

import DOM from './companySelector';

class UserLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,

            location_number: '',
            title: '',
            sub_account_number: '',
            location_name: '',
            address_1: '',
            address_2: '',
            town: '',
            county: '',
            postcode: '',
            country: '',
            telephone: '',
            email: '',
            delivery_instructions: '',
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getParentCompaniesListRequest();
    }

    componentDidUpdate(prevProps) {
        const { onChange } = this.props;
        const { selectedSubCompany: oldCompany } = prevProps;
        const { selectedSubCompany: newCompany } = this.props;
        if (oldCompany !== newCompany) {
            onChange(newCompany);
        }
    }

    topCompanyChange = (val) => {
        const { actions } = this.props;
        actions.getChildCompanyListRequest({ company_id: val });
    }

    subCompanyChange = (val) => {
        const { actions } = this.props;
        actions.selectSubCompany({ company_id: val });
    }

    createButtonClicked = () => {
        const { actions } = this.props;
        this.setState({
            editing: true,
            location_number: '',
            title: '',
            sub_account_number: '',
            location_name: '',
            address_1: '',
            address_2: '',
            town: '',
            county: '',
            postcode: '',
            country: '',
            telephone: '',
            email: '',
            delivery_instructions: '',
        });
        actions.selectSubCompany({ company_id: null });
    }

    editButtonClicked = () => {
        const { subCompanyInfo } = this.props;
        const subCompanyInfoAddress = subCompanyInfo.address || {};
        this.setState({
            editing: true,

            location_number: subCompanyInfo.location_number,
            title: subCompanyInfo.title,
            sub_account_number: subCompanyInfo.sub_account_number,
            location_name: subCompanyInfo.location_name,
            address_1: subCompanyInfoAddress.address_1,
            address_2: subCompanyInfoAddress.address_2,
            town: subCompanyInfoAddress.town,
            county: subCompanyInfoAddress.county,
            postcode: subCompanyInfoAddress.postcode,
            country: subCompanyInfoAddress.country,
            telephone: subCompanyInfo.telephone,
            email: subCompanyInfo.email,
            delivery_instructions: subCompanyInfo.delivery_instructions,
        });
    }

    validate = () => {
        const {
            title,
            address_1,
            postcode,
            telephone,
            email,
            location_name,
        } = this.state;
        let error = false;

        if (!address_1 || !title || !postcode || !telephone || !email || !location_name) {
            error = true;
        }

        if (!address_1) {
            message.error('Address 1 cannot be empty');
        }
        if (!title) {
            message.error('Location name cannot be empty');
        }
        if (!postcode) {
            message.error('Postcode cannot be empty');
        }
        if (!telephone) {
            message.error('Phone number cannot be empty');
        }
        if (!email) {
            message.error('Email cannot be empty');
        }
        if (!location_name) {
            message.error('Building name cannot be empty');
        }

        return !error;
    }

    saveButtonClicked = () => {
        const { actions, selectedTopCompany, selectedSubCompany } = this.props;
        const {
            location_number,
            title,
            sub_account_number,
            location_name,
            address_1,
            address_2,
            town,
            county,
            postcode,
            country,
            telephone,
            email,
            delivery_instructions,
        } = this.state;

        if (!this.validate()) {
            return;
        }

        const data = {
            location_number,
            title,
            sub_account_number,
            location_name,
            address_1,
            address_2,
            town,
            county,
            postcode,
            country,
            telephone,
            email,
            delivery_instructions,
        };
        if (selectedSubCompany) {
            data.id = selectedSubCompany;
        }
        data.parent_id = selectedTopCompany;

        actions.updateCompanyRequest(data);
        this.setState({ editing: false });
    }

    backButtonClicked = () => {
        this.setState({ editing: false });
    }

    render() {
        return this.view();
    }
}

UserLoginForm.defaultProps = {
    onChange: () => null,
    topCompanies: [],
    subCompanies: [],
    selectedTopCompany: null,
    selectedSubCompany: null,
    subCompanyInfo: {},
};

UserLoginForm.propTypes = {
    onChange: PropTypes.func,
    actions: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    topCompanies: PropTypes.array,
    subCompanies: PropTypes.array,
    selectedTopCompany: PropTypes.number,
    selectedSubCompany: PropTypes.number,
    subCompanyInfo: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.company.isFetching,
        topCompanies: state.company.topCompanies,
        subCompanies: state.company.subCompanies,
        selectedTopCompany: state.company.selectedTopCompany,
        selectedSubCompany: state.company.selectedSubCompany,
        subCompanyInfo: state.company.subCompanies.find((comp) => `${comp.id}` === `${state.company.selectedSubCompany}`) || {},
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...companyActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginForm);
