/* global sessionStorage */
import React from 'react';
import GoogleMapReact from 'google-map-react';

import { Row, Col } from 'antd';

import Chat from '../../components/Chat';
import Icon from '../../elements/Icon';
import Layout from '../../components/layout/Layout';

import SearchBar from '../../components/SearchBar';
import SearchResults from '../../components/SearchResults';
import SupplierDetails from '../../components/SupplierDetails';
import LocationPicker from '../../components/LocationPicker';

import './style.scss';

const { GOOGLE_MAPS_KEY } = require('../../core/constants').default;

export default function () {
    const { actions, searchParams, resultCount, userLatLng, resultsSidebar, showSupplierDetails, currentLocation, defaultLocation } = this.props;
    const { showChat, isMobile, showSearch, map, maps, locationModalVisible } = this.state;

    const sessionInUse = sessionStorage.getItem('sessionInUse');
    let visible = (sessionInUse === 'false' || sessionInUse === null) ? true : locationModalVisible;
    if (currentLocation == null && defaultLocation == null) {
        visible = true;
    }

    const toolbar = [
        { label: 'Quick Guide', onClick: this.downloadGuide, iconName: 'question' },
        { label: 'Your location', onClick: this.showLocationModal, iconName: 'target' },
        { label: 'Contact Us', iconName: 'question', onClick: actions.openHelpModal },
        { label: "Can't find what you're looking for?", onClick: this.checkAddSupplierAccess, iconName: 'looking-for' },
    ];

    const zoom = 15;
    const defaultCenter = userLatLng && (userLatLng.lat !== 0 && userLatLng.lng !== 0) ? userLatLng : {
        lat: 51.461340,
        lng: -0.930240,
    };

    const navigation = [{ label: 'Scout', clickaction: this.clearPage }, { label: 'Find a Supplier' }];

    const LocationMarker = () => (
        <div>
            <Icon name="human" className="icon-location-map-center" />
            <div className="icon-location-inner-circle" />
            <div className="icon-location-outer-circle" />
        </div>
    );

    return (
        <Layout
            className="default-map"
            title={<div>Welcome</div>}
            headerTitle="Find a Supplier"
            hideUserAvatar
            showMenu
            toolbar={toolbar}
            breadcrumb={navigation}>
            <div className="map-placeholder">
                <GoogleMapReact
                    options={this.createMapOptions()}
                    style={{ width: '100%' }}
                    bootstrapURLKeys={{ libraries: 'places', key: GOOGLE_MAPS_KEY }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={defaultCenter}
                    defaultZoom={zoom}
                    // eslint-disable-next-line no-shadow
                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}>
                    <LocationMarker lat={defaultCenter.lat} lng={defaultCenter.lng} />
                </GoogleMapReact>
                <div onClick={() => this.centerMap()} className="crosshair"><Icon name="target" /></div>
                {showChat ? (
                    <>
                        <Row align="middle" justify="center" style={{ minHeight: '83vh' }}>
                            <Col><Chat handleEnd={this.handleEnd} toggleChat={this.toggleChat} /></Col>
                        </Row>
                        <div className="chat-background" />
                    </>
                ) : (
                    <Row type="flex">
                        {isMobile && !showSearch ? (
                            <button className="btn show_search_button" onClick={() => this.showSearchForm()} type="button">
                                <Icon name="search" />
                            </button>
                        ) : '' }
                        {isMobile && showSearch ? (
                            <button className="btn hide_search_button" onClick={() => this.hideSearchForm()} type="button">
                                <Icon name="close" />
                            </button>
                        ) : '' }
                        {showSearch ? (
                            <div className="search-wrapper">
                                <Col className="sidebar-container-col">
                                    {resultsSidebar ? <SearchResults getSupplierDetails={this.getSupplierDetails} selectSupplier={this.selectSupplier} /> : ''}
                                    {showSupplierDetails ? <SupplierDetails showSupplierAreas={this.showSupplierAreas} backToSearch={this.backToSearch} /> : ''}
                                </Col>

                                <Col className="flgrow" style={{ pointerEvents: 'none' }}>
                                    <div className="column-space-between">
                                        {((resultsSidebar || showSupplierDetails) && isMobile) || showChat ? '' : <SearchBar isMobile={isMobile} activateFindNearestPin={this.activateFindNearestPin} />}
                                        {((resultsSidebar && !isMobile) || (isMobile && !resultsSidebar && !showSupplierDetails && resultCount)) ? (
                                            <div className="message bottom" style={{ pointerEvents: 'all' }}>
                                                <span>
                                                    Found {resultCount} suppliers matching the term <strong>{searchParams.name}</strong> in {searchParams.catName !== '' && searchParams.catName !== undefined ? `category ${searchParams.catName}` : 'all categories'} <br />
                                                </span>
                                                <span>
                                                    {isMobile && resultCount ? (
                                                        <>
                                                            <button onClick={() => actions.showResultsSidebar()} type="button" className="btn underlined">
                                                                Open Found
                                                            </button>
                                                            <span className="separator-vert" />
                                                        </>
                                                    ) : '' }
                                                    <button onClick={() => this.resetSearch()} type="button" className="btn underlined">
                                                        Clear Search
                                                    </button>
                                                </span>
                                            </div>
                                        ) : '' }
                                    </div>
                                </Col>
                            </div>
                        ) : '' }
                    </Row>
                )}
            </div>

            <LocationPicker
                maps={maps}
                map={map}
                locationModalVisible={visible}
                closeLocationModal={this.closeLocationModal}
                resetLocation={this.resetLocation}
                updatePins={this.updatePins} />
        </Layout>
    );
}
