import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Icon from '../../elements/Icon';
import './style.scss';

export default function () {
    const { handleEnd, toggleChat, userAvatar } = this.props;

    const chatTheme = {
        background: '#f5f8fb',
        fontFamily: 'Helvetica Neue',
        headerBgColor: '#2b77e3',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#18c9e5',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    };

    const chatHeader = (
        <div className="chat-header">
            <p>Request to use a new supplier</p>
            <div onClick={toggleChat} className="close-chat"><Icon name="close" /></div>
        </div>
    );

    return (
        <ThemeProvider theme={chatTheme}>
            <ChatBot
                width={`${this.state.windowWidth}px`}
                handleEnd={handleEnd}
                headerComponent={chatHeader}
                avatarStyle={{ marginTop: '70px', height: '50px', width: '50px' }}
                bubbleStyle={{ marginBottom: '20px', marginLeft: '50px' }}
                userAvatar={userAvatar}
                submitButtonStyle={{
                    borderLeft: '1px solid #E1E1ED',
                    margin: '10px 0',
                    padding: '4px 16px 2px 16px',
                    fill: '#3E79DD',
                }}
                steps={[
                    {
                        id: 1,
                        message: 'Hi there, I’m the Supply Chain Hero! How can I help?',
                        trigger: 2,
                    },
                    {
                        id: 2,
                        options: [
                            { value: 1, label: 'Product/Service', trigger: 3 },
                            { value: 2, label: 'Supplier', trigger: 10 },
                            { value: 3, label: 'I have an invoice', trigger: 14 },
                        ],
                        metadata: { isUser: true },
                    },

                    // Product/Service branch
                    {
                        id: 3,
                        message: 'Have you used the search button? You can put products and services in there, not just suppliers.',
                        trigger: 4,
                    },
                    {
                        id: 4,
                        user: true,
                        trigger: 5,
                        metadata: { isUser: true },
                    },
                    {
                        id: 5,
                        message: 'OK, thanks. What were you searching for?',
                        trigger: 6,
                    },
                    {
                        id: 6,
                        user: true,
                        trigger: 7,
                        metadata: { isUser: true },
                    },
                    {
                        id: 7,
                        message: 'Does this fit under any of these categories?',
                        trigger: 8,
                    },
                    {
                        id: 8,
                        options: [
                            { value: 1, label: 'Food/Beverage', trigger: 9 },
                            { value: 2, label: 'Non-Food Products', trigger: 9 },
                            { value: 3, label: 'Non-Food Services', trigger: 9 },
                        ],
                        metadata: { isUser: true },
                    },
                    {
                        id: 9,
                        message: 'Wonderful- our Supply Chain Services team are now on it. They\'ll get back to you when they find something.',
                        end: true,
                    },

                    // Supplier branch
                    {
                        id: 10,
                        message: 'Have you used the search button? You can search by supplier name, category, post code, anything!',
                        trigger: 11,
                    },
                    {
                        id: 11,
                        user: true,
                        trigger: 12,
                        metadata: { isUser: true },
                    },
                    {
                        id: 12,
                        message: 'Sounds like you might need to onboard a new supplier. Here\'s where you need to start...',
                        trigger: 13,
                    },
                    {
                        id: 13,
                        component: (
                            <a href="https://cpsm3ry.onk2.com/Runtime/Runtime/Form/Newwshgroup__sharepoint__com______sites______supplychain__Solutions__Lists__NewSupplierRequestItemForm/">Supplier onboarding</a>
                        ),
                        end: true,
                    },

                    // Invoice branch
                    {
                        id: 14,
                        message: 'Ah, that’s a shame! You should use this supplier search first instead of engaging with a new supplier – we may already have what you need. Please fill out this one-off supplier form here.',
                        trigger: 15,
                    },
                    {
                        id: 15,
                        component: (
                            <a href="https://cpsm3ry.onk2.com/Runtime/Runtime/Form/Newwshgroup__sharepoint__com______sites______supplychain__Solutions__Lists__NewSupplierRequestItemForm/">Supplier form</a>
                        ),
                        end: true,
                    },
                ]} />
        </ThemeProvider>
    );
}
