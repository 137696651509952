import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Layout, Input, Form, Select, Divider, Drawer } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { filter, find, isNull } from 'underscore';

import Menu from '../Menu';
import Breadcrumb from '../Breadcrumb';
import CompanySelect from '../CompanySelect';
import Icon from '../../../elements/Icon';
import WhsLogo from '../../../assets/images/wsh-logo.svg';
import userAvatar from '../../../assets/images/default-avatar.png';
import './style.scss';

export default function () {
    const {
        toolbar,
        menu,
        showMenu,
        backLink,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
        breadcrumb,
        helpModalOpen,
        userCompanies,
        actions,
        user,
    } = this.props;
    const { profileDrawerOpen } = this.state;
    const backLinkHtml = backLink && (
        <div className="backlink">
            <Link to={backLink.link}>
                <Icon name="arrow-left" />
            </Link>
            <span>{backLink.title}</span>
        </div>
    );

    let companies = [];
    let company = {};
    if (userCompanies) {
        company = find(userCompanies, c => c.id === user.active_company_id);
        if (company) {
            if (company.parent_id) {
                const parent = find(userCompanies, c => c.id === company.parent_id);
                company.parent = parent;
            }

            companies = filter(userCompanies, c => (c.id !== company.id && !c.parent_id && c.id !== company.parent_id));
        }
    }

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between" align="middle" style={{ height: 'inherit' }}>
                    <Col>
                        <Row type="flex" align="middle" className="header__topline">
                            {backLinkHtml}
                            {menu.length > 0 || showMenu ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null}
                            {menu && menu.length > 0 ? <span className="separator-vert hide-xs" /> : null}
                            {menu && menu.length > 0 ? <img src={WhsLogo} alt="WHS" className="hide-xs" /> : null}
                            {breadcrumb ? <Breadcrumb navigation={breadcrumb} /> : null}
                        </Row>
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    <Col>
                        <div className="profile-wrap">
                            <Button className="menu-button transparent" onClick={this.openProfileDrawer}>
                                <div className="profile__avatar">
                                    <img src={user && user.userData ? user.profile_url : userAvatar} alt="" />
                                </div>
                            </Button>
                            <Drawer
                                visible={profileDrawerOpen}
                                closable={false}
                                placement="right"
                                width={224}
                                className="drawer">
                                <Button className="drawer__close-btn btn transparent" onClick={this.closeProfileDrawer}>
                                    <Icon name="arrow-right" />
                                </Button>

                                <CompanySelect
                                    mobile
                                    avatar={user && user.userData ? user.profile_url : userAvatar}
                                    company={company}
                                    companies={companies}
                                    onCompanyChange={this.updateCompany} />

                                <div className="drawer__footer">
                                    <Link to="/logout" className="btn underlined">Sign Out</Link>
                                </div>
                            </Drawer>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <div className="toolbar">
                            {backLinkHtml}
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'default'}
                                    className="toolbar-button transparent"
                                    href={button.link || '#'}
                                    onClick={button.onClick || null}>
                                    <Icon name={button.iconName} />
                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
            <Modal
                centered
                footer={null}
                visible={helpModalOpen}
                onCancel={actions.closeHelpModal}>
                <h3 className="text-center">Get Help</h3>
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={actions.sendHelpRequestFormRequest}>

                    <Form.Item
                        label="Your name"
                        name="name"
                        rules={[{ required: true, message: 'Please provide your name' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Your direct dial"
                        name="phone_number"
                        rules={[{ required: true, message: 'Please provide your number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Your email address"
                        name="email"
                        rules={[{ required: true, message: 'Please provide your email address' }]}>
                        <Input />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label="Company"
                        name="company_id"
                        rules={[{ required: true, message: 'Please provide your company' }]}>
                        <Select>
                            {userCompanies.filter(c => isNull(c.parent_id)).map((userCompany) => (
                                <Select.Option key={userCompany.id} value={userCompany.id}>{userCompany.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Location number"
                        name="location_number"
                        rules={[{ required: true, message: 'Please provide your company location number' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Location name"
                        name="location_name"
                        rules={[{ required: true, message: 'Please provide your company location name' }]}>
                        <Input />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label="What do  you need help with?"
                        name="request_details"
                        rules={[{ required: true, message: 'Please explain the issue' }]}>
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item>
                        <div className="text-center">
                            <Button htmlType="submit" className="btn primary" style={{ width: 161, height: 48 }}>
                                Send
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
