/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    SEND_HELP_REQUEST_FORM_REQUEST,
    SEND_HELP_REQUEST_FORM_SUCCESS,
    SEND_HELP_REQUEST_FORM_FAILURE,

    OPEN_HELP_MODAL,
    CLOSE_HELP_MODAL,

} = require('./helpActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    modalOpen: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function helpReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case SEND_HELP_REQUEST_FORM_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);


    case SEND_HELP_REQUEST_FORM_SUCCESS:
        return state.set('isFetching', false)
            .set('modalOpen', false);


    case SEND_HELP_REQUEST_FORM_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);


    case OPEN_HELP_MODAL:
        return state.set('modalOpen', true);
    case CLOSE_HELP_MODAL:
        return state.set('modalOpen', false);
    default:
        return state;
    }
}
