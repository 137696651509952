import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchApiAuth } from '../utils/api';

import {
    getCategoriesSuccess,
    getCategoriesFailure,
} from './categoryActions';

const {
    GET_CATEGORIES_REQUEST,
} = require('./categoryActions').constants;

function* getCategories() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/category',
        });

        yield put(getCategoriesSuccess(response.data));
    } catch (e) {
        yield put(getCategoriesFailure(e.response ? e.response.data : e));
    }
}

export default function* gallerySaga() {
    yield* [
        takeEvery(GET_CATEGORIES_REQUEST, getCategories),
    ];
}
