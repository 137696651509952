import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import QueryString from 'qs';
import React, { Component } from 'react';

import { isEmpty } from 'underscore';
import * as userActions from '../../core/user/userActions';
import * as searchActions from '../../core/search/searchActions';

import './style.scss';

/**
 * This page automatically pulls the bearer token from the URL parameters and
 * stores it in lockr. This means that the app can be authenticated from the
 * backend
 */
class AutoLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: null,
        };
    }

    componentDidMount() {
        const { actions, location } = this.props;
        actions.clearUserData();
        actions.clearSearch();
        actions.clearSearchReducer();
        const result = this.findToken(location.search.replace(/^\?/, ''));
        this.setState({ success: result });
    }

    componentDidUpdate() {
        const { success } = this.state;
        const { actions } = this.props;
        if (success === true) {
            actions.getUserDataRequest();
        }
    }

    findToken = (searchString) => {
        const { actions } = this.props;
        // Adding a try-catch as if anything fails we want to show the failure
        // page
        try {
            if (!searchString.includes('token')) {
                this.setState({ success: false });
                return false;
            }
            const params = QueryString.parse(searchString);
            const auth_token = params.token;
            actions.saveTokenRequest({ auth_token });
            return true;
        } catch (e) {
            return false;
        }

    }

    render() {
        const { success } = this.state;
        const { userDataIsEmpty } = this.props;
        return (
            <div className="auto-login-wrapper">
                <div className="centered-box">
                    {
                        // eslint-disable-next-line no-nested-ternary
                        success === null || userDataIsEmpty
                            ? <><h1>Logging in</h1><Spin size="large" /></>
                            : success === true
                                ? <><h1>Logged in</h1><a href="/">Click here if you&apos;re not redirected</a></>
                                : <h1>Unable to log in</h1>
                    }
                </div>
            </div>
        );
    }
}

AutoLogin.propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userDataIsEmpty: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        userDataIsEmpty: isEmpty(state.user.userData),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
                ...searchActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AutoLogin);
