import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_FAQ_DATA_REQUEST: null,
    GET_FAQ_DATA_SUCCESS: null,
    GET_FAQ_DATA_FAILURE: null,

    GET_FAQ_CATEGORIES_REQUEST: null,
    GET_FAQ_CATEGORIES_SUCCESS: null,
    GET_FAQ_CATEGORIES_FAILURE: null,
});

export const {
    getFaqDataRequest,
    getFaqDataSuccess,
    getFaqDataFailure,
} = createActions(
    constants.GET_FAQ_DATA_REQUEST,
    constants.GET_FAQ_DATA_SUCCESS,
    constants.GET_FAQ_DATA_FAILURE,
);

export const {
    getFaqCategoriesRequest,
    getFaqCategoriesSuccess,
    getFaqCategoriesFailure,
} = createActions(
    constants.GET_FAQ_CATEGORIES_REQUEST,
    constants.GET_FAQ_CATEGORIES_SUCCESS,
    constants.GET_FAQ_CATEGORIES_FAILURE,
);
