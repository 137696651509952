import React from 'react';
import moment from 'moment';
import { Button, Row, Col } from 'antd';
import Icon from '../../elements/Icon';
import './style.scss';

export default function () {
    const {
        type,
        accountNumber,
        priceLevel,
        startDate,
        endDate,
        supplierName,
        supplierWebsite,
        supplyStartDate,
    } = this.props;

    const time = moment(supplyStartDate);
    const diff = time.diff(moment());
    const isTimePast = diff < 0;

    const dateFormat = (date) => {
        if (!date) {
            return date;
        }
        const momentObject = moment(date);
        return momentObject.format('DD/MM/YYYY');
    };

    return (
        <div className="card">
            <div className="card__header">
                <h4 className="h-4">Existing Supplier Request</h4>
            </div>
            <Row type="flex" gutter={32}>
                <Col span={6}>
                    <h5 className="h-5">Supplier</h5>
                    <p className="mt-1">{supplierName}</p>
                    <p className="mt-1">
                        <a href={supplierWebsite} className="link">
                            {supplierWebsite}
                        </a>
                    </p>
                </Col>
                <Col span={6}>
                    <h5 className="h-5">{isTimePast ? 'Supply Started' : 'Required Supply Start'}</h5>
                    <Row type="flex" gutter={8} className="mt-1">
                        <Col>
                            <Icon name="calendar" className="fz-16" />
                        </Col>
                        <Col>
                            <p className="mt-1">{dateFormat(supplyStartDate)}</p>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <h5 className="h-5">Additional information</h5>
                    <p className="mt-1">Account #: {accountNumber}</p>
                    <p className="mt-1">Price level: {priceLevel}</p>
                    <p className="mt-1">Start date: {dateFormat(startDate)}</p>
                    <p className="mt-1">End date: {dateFormat(endDate)}</p>
                </Col>
                <Col span={6} className="text-right">
                    <Button
                        className={`bordered middleplus ${type.toLowerCase()}`}>
                        {type}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
