import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';

import * as searchActions from '../../core/search/searchActions';
import * as userActions from '../../core/user/userActions';

import DOM from './sidebarResults';

class SidebarResults extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        // Related to infoDescription view more/less option.
        this.state = {
            cutOffToggle: Array(Object.keys(this.props.results).length).fill(true),
            cutOffHeight: 80,
        };
        this.accordionContent = [];
    }

    componentDidMount() {
        this.updateResults();
    }

    componentDidUpdate(prevProps) {
        const { mapLocation } = this.props;
        const { mapLocation: prevMapLocation } = prevProps;

        if (mapLocation && !isEqual(mapLocation, prevMapLocation)) {
            const { coordinates: { lat, lng } } = mapLocation;
            this.updateResults(lat, lng);
        }
        if (!isEqual(prevProps, this.props)) {
            this.forceUpdate();
        }
    }

    updateResults = (lat, lng) => {
        const { actions, searchParams, user } = this.props;
        const { category, name, sortby, companyId, latitude, longitude, radius } = searchParams;
        const data = {
            category,
            name,
            start: 0,
            finish: 10,
            sortby,
            companyId: user.userData.active_company_id || companyId,
            latitude: lat || latitude,
            longitude: lng || longitude,
            radius,
        };

        actions.getResultsRequest(data);
        actions.getPinsRequest({
            category,
            name,
            companyId: user.userData.active_company_id || companyId,
            latitude: lat || latitude,
            longitude: lng || longitude,
            radius,
        });
    }

    handleSidebarClose = () => {
        const { actions } = this.props;
        actions.hideResultsSidebar();
    }

    handleResultsScroll = (e) => {
        const { actions, searchParams, results } = this.props;
        const { category, name, sortby, companyId, latitude, longitude, radius } = searchParams;

        const data = {
            category,
            name,
            start: results.length,
            finish: 10,
            sortby,
            companyId,
            latitude,
            longitude,
            radius,
        };

        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            actions.getResultsRequest(data);
        }
    }

    handleSortResults = (value) => {
        const { actions, searchParams } = this.props;
        const { category, name, companyId, latitude, longitude, radius } = searchParams;

        const data = {
            category,
            name,
            start: 0,
            finish: 10,
            sortby: value,
            companyId,
            latitude,
            longitude,
            radius,
        };

        actions.clearSearch();
        actions.getResultsRequest(data);
    }

    handlePinGroupChange = (groupBy) => {
        const { actions, searchParams } = this.props;
        const { category, name, companyId, latitude, longitude, radius } = searchParams;

        const pinData = {
            category,
            name,
            companyId,
            latitude,
            longitude,
            radius,
            groupBy,
        };

        actions.getPinsRequest(pinData);
    }

    // Ref assign function
    handleRefAssign = (accordionContent, key) => {
        this.accordionContent[key] = accordionContent;
    }

    // Triggered by readOption click.
    handleInfoDescriptionResize = (key) => {
        const { cutOffToggle } = this.state;
        const toggles = cutOffToggle;
        if (toggles[key] == null) {
            toggles[key] = false;
        } else {
            toggles[key] = !toggles[key];
        }
        this.setState({
            cutOffToggle: toggles,
        });
    }

    // Triggered as part of render method.
    renderInfoDescription = (key, result) => {
        let style = null;
        let renderViewOption = null;
        // Protection against unassigned ref
        if (this.accordionContent[key]) {
            style = {
                maxHeight: (this.state.cutOffToggle[key] !== false) ? `${this.state.cutOffHeight}px` : null,
                minHeight: (this.state.cutOffToggle[key] !== false) ? `${this.state.cutOffHeight}px` : `${this.accordionContent[key].scrollHeight}px`,
            };
            if (this.accordionContent[key].offsetHeight >= this.state.cutOffHeight) {
                renderViewOption = true;
            } else {
                style = {
                    maxHeight: (this.state.cutOffToggle[key] !== false) ? `${this.state.cutOffHeight}px` : null,
                    overflowY: (this.state.cutOffToggle[key] !== false) ? 'hidden' : 'auto',
                };
            }
        }
        return (
            <div>
                <div className="info__description" style={style} ref={accordionContent => this.handleRefAssign(accordionContent, key)}>
                    <p className="mt-2">{result.description}</p>
                </div>
                { (renderViewOption === true)
                    ? (
                        <div className="viewOptionContainer">
                            <Button className="viewOption" onClick={() => this.handleInfoDescriptionResize(key)}>{ (this.state.cutOffToggle[key] !== false) ? 'view more' : 'view less'}</Button>
                        </div>
                    )
                    : null }
            </div>
        );
    }

    render() {
        return this.view(this.props);
    }
}

SidebarResults.defaultProps = {
    mapLocation: null,
};

SidebarResults.propTypes = {
    actions: PropTypes.object.isRequired,
    searchParams: PropTypes.object.isRequired,
    resultCount: PropTypes.number.isRequired,
    results: PropTypes.array.isRequired,
    ShouldShowResultsSidebar: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    mapLocation: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        results: state.search.results,
        resultCount: state.search.resultCount,
        searchParams: state.search.searchParams,
        ShouldShowResultsSidebar: state.search.resultsSidebar,
        user: state.user,
        mapLocation: state.user.mapLocation,
        groupBy: state.search.groupBy,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...searchActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarResults);
