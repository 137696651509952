/* global document */

import { call, put, takeEvery, all, delay } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    getRelatesToOptionsSuccess,
    getRelatesToOptionsFailure,

    getComplaintTypeOptionsSuccess,
    getComplaintTypeOptionsFailure,

    sendComplaintFormSuccess,
    sendComplaintFormFailure,

    downloadFileSuccess,
    downloadFileFailure,

} from './reportActions';

const {
    GET_RELATES_TO_OPTIONS_REQUEST,
    GET_COMPLAINT_TYPE_OPTIONS_REQUEST,
    SEND_COMPLAINT_FORM_REQUEST,
    DOWNLOAD_FILE_REQUEST,
} = require('./reportActions').constants;

function* getRelatesToOptions() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'complaint/complaint-relates-to',
        });

        yield put(getRelatesToOptionsSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getRelatesToOptionsFailure(e.message ? e.message : e));
    }
}

function* getComplaintTypeOptions() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'complaint/complaint-types',
        });

        yield put(getComplaintTypeOptionsSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getComplaintTypeOptionsFailure(e.message ? e.message : e));
    }
}

function* sendComplaintForm(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'complaint/create',
            body: actions.payload,
        });
        message.success('Successfully sent form');
        history.push('/');
        yield put(sendComplaintFormSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(sendComplaintFormFailure(e.message ? e.message : e));
    }
}

function* downloadFile(action) {
    try {
        const response = yield call(
            fetchApiAuth({
                url: 'complaint/download-url',
                method: 'GET',
                params: { file_id: action.payload.file_id },
            })
                .then(r => {
                    const anchor = document.createElement('a');
                    document.body.appendChild(anchor);
                    const objectUrl = r.data.url;

                    anchor.download = `${r.data.file_name}`;
                    anchor.href = objectUrl;

                    anchor.click();

                    anchor.remove();
                })
                .catch(e => message.error(e.response.data.error ? e.response.data.error : e.message)),
        );

        yield put(downloadFileSuccess(response));
    } catch (e) {
        yield delay(1000);
        yield put(downloadFileFailure(e.message ? e.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(GET_RELATES_TO_OPTIONS_REQUEST, getRelatesToOptions),
        takeEvery(GET_COMPLAINT_TYPE_OPTIONS_REQUEST, getComplaintTypeOptions),
        takeEvery(SEND_COMPLAINT_FORM_REQUEST, sendComplaintForm),
        takeEvery(DOWNLOAD_FILE_REQUEST, downloadFile),
    ]);
}
