import React from 'react';
import { Tabs, Pagination, Spin } from 'antd';
import Layout from '../../components/layout/Layout';
import './style.scss';
import RequestItem from '../../components/RequestItem';

const { TabPane } = Tabs;

export default function () {
    const { requests, total, loading, page, user } = this.props;
    const toolbar = [{ label: 'Contact Us', iconName: 'question', onClick: this.props.actions.openHelpModal }];
    const navigation = [{ label: 'Scout', link: '/search' }, { label: 'Your Requests' }];

    const tabsList = [
        { key: 'all', title: 'All' },
        { key: 'submitted', title: 'Submitted' },
        { key: 'pending', title: 'Pending' },
        { key: 'approved', title: 'Approved' },
        { key: 'rejected', title: 'Rejected' },
    ];

    const rawUserRole = (user.userData && user.userData.role && user.userData.role.name) || 'user';
    const userRole = rawUserRole.toLowerCase();
    if (userRole !== 'user' && userRole !== 'scorchsoft') {
        return 'Not authorized';
    }

    return (
        <Layout title={<div>Welcome</div>} showMenu toolbar={toolbar} headerTitle="Your requests" floatingHeader breadcrumb={navigation}>
            <div className="tabs-wrap">
                <div className="bg_palegrey">
                    <div className="tabs-contr">
                        <div style={{ height: '56px' }} />
                        <h1 className="h-2">Your requests</h1>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <Tabs
                        onChange={this.onTabsChange}
                        animated={false}
                        activeKey={this.state.activeTabKey}
                        className="tabs-custom">
                        {tabsList.map((pane) => (
                            <TabPane tab={pane.title} key={pane.key}>
                                {requests.map((request) => (
                                    <RequestItem
                                        type={request.status_string}
                                        accountNumber={request.supplier_account_number}
                                        startDate={request.start_date}
                                        endDate={request.end_date}
                                        supplierName={request.supplier_name}
                                        supplierWebsite={request.supplier_site}
                                        supplyStartDate={request.start_date} />
                                ))}
                                {
                                    requests.length > 0
                                        ? null
                                        : (
                                            <div className="no-results-wrapper">
                                                <div className="no-results-inner">
                                                    <p>No requests found</p>
                                                </div>
                                            </div>
                                        )
                                }
                            </TabPane>
                        ))}
                    </Tabs>
                    <div className="tabs-contr">
                        <Pagination
                            className="pagination-custom"
                            current={page}
                            total={total}
                            onChange={this.onPageChange}
                            showSizeChanger={false} />
                    </div>
                </Spin>
            </div>
        </Layout>
    );
}
