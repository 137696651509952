import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';

import defaultLogo from '../../../assets/images/scout-logo.png';

import * as userActions from '../../../core/user/userActions';

import DOM from './menu';

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            profileCollapsed: true,
        };
        this.view = DOM;
    }

    openDrawer = () => {
        this.setState({ menuOpen: true });
    }

    closeDrawer = () => {
        this.setState({ menuOpen: false });
    }

    toggleProfile = () => {
        const { profileCollapsed } = this.state;
        this.setState({ profileCollapsed: !profileCollapsed });
    }

    updateCompany = (companyId) => {
        const { actions } = this.props;
        actions.updateActiveCompanyRequest({ company_id: companyId });
    }

    render() {
        return this.view(this.props);
    }
}

Menu.propTypes = {
    actions: PropTypes.object.isRequired,
    items: PropTypes.array,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    placement: PropTypes.string,
    width: PropTypes.number,
};

Menu.defaultProps = {
    items: [],
    logo: defaultLogo,
    placement: 'left',
    width: 400,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
