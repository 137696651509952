import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getGuideSuccess,
    getGuideFailure,
} from './settingActions';

const {
    GET_GUIDE_REQUEST,
} = require('./settingActions').constants;

function* getGuide() {
    try {
        const response = yield call(fetchApiAuth({
            method: 'GET',
            url: '/settings/quick-guide',
        })
        // eslint-disable-next-line no-shadow
            .then(response => {
                const anchor = window.document.createElement('a');
                window.document.body.appendChild(anchor);

                anchor.download = `guide.${response.data.extension}`;
                anchor.href = response.data.url;
                anchor.target = '_blank';

                anchor.click();
            }));

        yield put(getGuideSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getGuideFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* gallerySaga() {
    yield* [
        takeEvery(GET_GUIDE_REQUEST, getGuide),
    ];
}
